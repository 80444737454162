@import "../structure/variables";




.page-wrap--development-page {
  background-color: #f5f3f6;
  .development-list {
    background-color: @white;
    padding-top: 70px;
  }
  .practice-list {
    background-color: #f4f4f4;
    &::after {
      background-color: #f4f4f4;
    }
  }
  section.works {
    background-color: #f4f4f4;
  }
  .works-button-wrapper {
    display: none;
  }
  .works-list-wrapper {
    //margin-top: -200px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
  }
}



@media only screen and (min-width: 992px) {
  .page-wrap--development-page {
    .practice-list__item:hover {
      background-color: @white;
    }
  }
}



@media only screen and (max-width: 991px) {
  .page-wrap--development-page {
    .development-list {
      padding-top: 60px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .page-wrap--development-page {
    .development-list {
      padding-top: 40px;
    }
  }
}

