@import "../structure/variables";

.page-wrap--service-page {
  background-color: #f5f3f6;
  section.works-title {
    font-family: ProximaNova;
    font-size: 22px;
    font-weight: bold;
    line-height: 120px;
    text-align: center;
    color: #000000;
    position: relative;
    z-index: 2;
    margin-bottom: -200px;
    padding-top: 50px;
    text-transform: uppercase;
  }
  section.works {
    margin-top: -30px;
    .works-slider .works-item {
      padding-top: 200px;
    }
  }
  .works-button-wrapper {
    //display: none;
  }
  .works-list-wrapper {
    //margin-top: -100px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
  }
}

section.service-tabs {
  background-color: @white;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}

.service-tabs-nav {
  border-bottom: solid 1px rgba(155, 155, 155, 0.23);
}

.service-tabs-nav__wrapper {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-tabs-nav__item {
  padding: 40px 15px 30px;
  cursor: pointer;
  position: relative;
}

.service-tabs-nav__item--active,
.service-tabs-nav__item:hover {
  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 5px;
    background-color: @red_main;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.5s;
  }
}

.service-tabs-wrapper {
  padding: 70px 100px 0;
  //height: 500px;
  //overflow: hidden;
}

.service-tabs__item {
  display: none;
  align-items: flex-start;
}

.service-tabs__item--active {
  display: flex;
}

.service-tabs__item-image-wrapper {
  flex: 0 0 340px;
  max-width: 340px;
  height: 340px;
  background-color: rgba(255, 0, 72, 0.1);
  border-radius: 50%;
  margin-right: 40px;
  position: relative;
}

.service-tabs__item-image {
  position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
}

.service-tabs__item-text {
  flex: 1;
  color: #4a4a4a;
  line-height: 1.5;
  li {
    list-style-type: none;
  }
  p {
    margin-bottom: 10px;
  }
}

.service-tabs__item-title {
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding-top: 10px;
  color: #000;
}

section.service-special {
  background-color: @white;
  padding: 100px 0;
}

.service-special_wrapper {
  max-width: 720px;
  margin: 0 auto;
  padding-left: 100px;
}

.service-special__title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
  line-height: 1;
}

.service-special__text {
  color: #4a4a4a;
  line-height: 1.5;
}

section.service-list {
  background-color: @white;
  padding: 0 15px;
}

.service-list-row {
  max-width: 960px;
  margin: 0 auto;
  padding: 50px 0 130px;
  position: relative;
  display: flex;
  &:first-child {
    &:not(.not-connections)::before {
      display: block;
      content: '';
      width: 1px;
      height: calc(~"100% - 300px + 70px");
      background-color: @red_main;
      position: absolute;
      top: 300px;
      right: 400px;
      z-index: 2;
    }
    &:not(.not-connections)::after {
      display: block;
      content: '';
      width: 11px;
      height: 11px;
      background-color: @red_main;
      border-radius: 50%;
      position: absolute;
      bottom: -80px;
      right: 395px;
      z-index: 3;
    }
    .service-list-row__number {
      top: -50px;
    }
  }
  &:last-child {
    padding-bottom: 0;
    margin-bottom: -35px;
  }
  &:first-child:not(.not-connections), &:last-child:not(.not-connections) {
    .service-list-item {
      &::before {
        display: block;
        content: '';
        width: 2000px;
        height: 1px;
        position: absolute;
        top: 120px;
        right: calc(~"100% - 30px");
        background-color: @red_main;
      }
      &::after {
        display: block;
        content: '';
        width: 10px;
        height: 10px;
        background-color: @red_main;
        position: absolute;
        top: 116px;
        right: calc(~"100% - 30px");
        border-radius: 50%;
      }
    }
  }
  &__wide-image {
    z-index: 1;
  }
}

.service-list-row--image-right {
  .service-list-item {
    padding: 80px 120px 80px 80px;
  }
  .service-list-item__image {
    right: 0;
  }
  .service-list-row__number {
    left: -95px;
  }
}

.service-list-row--image-left {
  .service-list-item {
    margin-left: auto;
    padding: 80px 80px 80px 120px;
  }
  .service-list-item__image {
    left: 0;
  }
  .service-list-row__number {
    right: -95px;
  }
}

.service-list-item {
  width: 100%;
  max-width: 540px;
  background-color: @white;
  box-shadow: 4px 4px 13px 0 rgba(182, 173, 189, 0.47);
  position: relative;
  z-index: 1;
}

.service-list-item__title {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 30px;
}

.service-list-item__text {
  color: #4a4a4a;
  line-height: 1.5;
  a:hover {
    text-decoration: underline;
  }
}

.service-list-item__image {
  position: absolute;
  top: 0;
  z-index: 1;
}

.service-list-row__number {
  font-size: 337px;
  line-height: 1;
  color: #e1d9e3;
  font-weight: 800;
  opacity: 0.25;
  position: absolute;
  top: -120px;
  z-index: 0;
}

section.service-result {
  padding: 170px 0 120px;
  background-color: #ffecf1;
  position: relative;
  &::before {
    display: block;
    content: '';
    width: 1px;
    height: 130px;
    background-color: @red_main;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  &::after {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    background-color: @red_main;
    border-radius: 50%;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.service-result-wrapper {
  max-width: 600px;
  margin: 0 auto;
}

.service-result__title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  margin-bottom: 30px;
}

.service-result__subtitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 60px;
}

.service-result__text {
  line-height: 1.5;
  color: #4a4a4a;
  ul {
    padding-left: 30px;
    li {
      list-style-type: disc;
      margin-bottom: 20px;
    }
  }
}

section.related-services {
  padding: 100px 30px 0px;
  background-color: @white;
}

.related-services__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25;
  text-transform: uppercase;
  margin-bottom: 45px;
  text-align: center;
}

.related-services-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.related-services__item {
  padding: 40px 50px;
  box-shadow: 0 0 19px 0 rgba(130, 130, 130, 0.21);
  width: 100%;
  max-width: 300px;
  margin: 0 20px;
}

.related-services__image-wrapper {
  width: 140px;
  height: 140px;
  background-color: rgba(255, 0, 72, 0.1);
  border-radius: 50%;
  position: relative;
  margin-bottom: 40px;
}

.related-services__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.related-services__link {
  span {
    font-size: 12px;
    font-weight: 700;
    color: @red_main;
    text-transform: uppercase;
    margin-right: 10px;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    transition: all 0.5s;
  }
}

.related-services__name {
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.3;
  height: 66px;
  overflow: hidden;
  a {
    color: #000;
  }
}

@media only screen and (min-width: 992px) {
  .related-services__link:hover {
    svg {
      transform: translateX(10px);
    }
  }
}

@media only screen and (max-width: 1199px) {
  .service-tabs-wrapper {
    padding: 70px 30px;
  }

  .service-list-row {
    padding-bottom: 100px;
  }

  section.related-services {
  }
}

@media only screen and (max-width: 991px) {
  .service-tabs-wrapper {
    padding: 50px 0;
  }

  .service-tabs__item-title {
    margin-bottom: 15px;
  }

  .service-tabs__item-image-wrapper {
    flex: 0 0 250px;
    max-width: 250px;
    height: 250px;
  }

  .service-special_wrapper {
    padding: 0 15px;
  }

  .service-list-row {
    &:first-child {
      &::before {
        top: 240px;
        right: 280px;
        height: calc(~"100% - 240px + 70px");
      }
      &::after {
        right: 275px;
      }
      .service-list-row__number {
        top: -100px;
      }
    }
  }

  .service-list-row--image-right {
    .service-list-item__image {
      right: -40px;
    }
    .service-list-item {
      padding: 60px 100px 60px 60px;
    }
    .service-list-row__number {
      left: -25px;
    }
  }

  .service-list-row--image-left {
    .service-list-item {
      padding: 60px 40px 60px 120px;
    }
    .service-list-item__image {
      left: -40px;
    }
    .service-list-row__number {
      right: -25px;
    }
  }

  .service-list-item__image {
    max-width: 370px;
  }

  .service-list-item {
    max-width: 500px;
  }

  .service-list-row__number {
    font-size: 300px;
  }

  section.service-result {
    padding-bottom: 80px;
  }

  section.related-services {
    padding: 60px 15px 60px;
  }

  .related-services__item {
    //padding: 40px 20px;
    &:last-child {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .service-tabs__item {
    flex-direction: column;
  }

  .service-tabs-nav {
    border-bottom: none;
  }

  .service-tabs-nav__wrapper {
    flex-direction: column;
  }

  .service-tabs-nav__item {
    width: 100%;
    padding: 15px 25px;
    border-bottom: solid 1px rgba(155, 155, 155, .23);
  }

  .service-tabs__item-image-wrapper {
    flex: auto;
    max-width: 100%;
    width: 200px;
    height: 200px;
    margin: 0 auto 20px;
  }

  .service-tabs__item-text {
    flex: auto;
  }

  .service-tabs__item-title {
    text-align: center;
  }

  .service-tabs-nav__item--active {
    &::after {
      width: 5px;
      height: 100%;
    }
  }

  .service-tabs-wrapper {
    padding: 40px 0;
  }

  section.service-special {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .service-list-row__number {
    display: none;
  }

  .service-list-row {
    flex-direction: column;
    padding: 0 0 50px;
    &::before, &::after {
      display: none !important;
    }
  }

  .service-list-item__image {
    position: static;
    order: 0;
  }

  .service-list-item {
    order: 1;
    padding: 30px 15px !important;
    box-shadow: none;
    &::before, &::after {
      display: none !important;
    }
  }

  .service-list-item__title {
    margin-bottom: 15px;
  }

  section.service-result {
    padding: 100px 0 50px;
    &::before, &::after {
      display: none !important;
    }
  }

  .service-result-wrapper {
    padding: 0 15px;
  }

  .service-result__subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .related-services-wrapper {
    flex-direction: column;
  }

  .related-services__item {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  section.service-list {
    padding: 0 0;
  }
}

// ==== animation ====

.service-list-item__image,
.service-list-row__wide-image {
  opacity: 0;
  &.fade-in {
    opacity: 1;
    transition: opacity 1s ease;
  }
}

.service-tabs__item-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: circle 20s linear infinite;
}

@keyframes circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.service-tabs__item-image {
  margin-top: 3%;
  animation: inner-circle 20s linear infinite;
}

@keyframes inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.related-services__item {
  transition: opacity .5s linear, transform 1s ease;
  opacity: 1;
  &.animate {
    opacity: 0;
    transform: translateY(40px);
  }
}

@media only screen and (min-width: 768px) {
  .related-services__item {
    &:nth-child(2) {
      transition-delay: .6s;
    }
    &:nth-child(3) {
      transition-delay: 1.2s;
    }
  }
}