@import "../structure/variables";

section.reviews-main {
  padding: 60px 0;
}

.reviews-main__title {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: #000000;
}

.reviews-main__subtitle {
  max-width: 580px;
  margin: 0 auto;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #544b5c;
}

section.reviews-list {
  padding: 0 15px 90px;
  max-width: 1230px;
  margin: 0 auto;

  .reviews-item {
    margin-bottom: 20px;
    padding: 0;
    &:nth-child(odd) {
      //box-shadow: 0 0 19px 0 rgba(130, 130, 130, 0.21);
    }
  }

  .reviews-item-wrapper {
    //box-shadow: none;
    margin: 0 auto;
    //padding-top: 70px;
    padding: 30px 30px 70px 90px;
  }

  .reviews-item__info {
    padding-right: 35px;
  }

  .reviews-item__photo {
    margin-bottom: 40px;
  }

  .reviews-item__hidden-text {
    position: relative;
    display: block;
    max-height: 255px;
    overflow: hidden;
    transition: 0.25s ease;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.21), #ffffff 100%);
      opacity: 1;
      visibility: visible;
      transition: 0.25s ease;
    }

    &.open {
      max-height: 9999px;
      transition: 0.25s ease;
      &:after {
        opacity: 0;
        visibility: hidden;
        transition: 0.25s ease;
      }
    }

  }

  .reviews-item__button {
    margin-top: 0px;
    svg {
      display: block;
      margin: 0 auto;
      width: 28px;
      height: 15px;
    }
  }

  .reviews-item__quote {
    margin-top: 25px;
  }

}

.reviews-list__button-wrapper {
  text-align: center;
}

.reviews-list__button {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: #544b5c;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 25px 15px;
  border: 1px solid #000000;
  border-radius: 100px;
  min-width: 230px;
  transition: all 0.5s;
  cursor: pointer;
}

@media only screen and (min-width: 992px) {
  .reviews-list__button:hover {
    background-color: #000000;
    color: @white;
  }
}

@media only screen and (max-width: 991px) {
  section.reviews-main {
    padding: 20px 0 40px;
  }

  .reviews-main__title {
    font-size: 22px;
  }

  section.reviews-list {
    padding: 0 15px 60px;
  }

  section.reviews-list .reviews-item-wrapper {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .reviews-main__title {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .reviews-main__subtitle {
    font-size: 16px;
  }

  section.reviews-list {
    .reviews-item-wrapper {
      flex-direction: column;
    }
    .reviews-item__text {
      display: block;
      flex: auto;
      max-width: 100%;
      text-align: center;
    }

    .reviews-item__hidden-text {
      max-height: 175px;
    }
  }
}