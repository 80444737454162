@import "../structure/variables";


section.cases-categories {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}
.cases-categories__item {
  width: 100%;
  max-width: 300px;
  height: 60px;
  padding: 5px 50px 5px 20px;
  border: solid 1px #d7ccdb;
  margin: 0 10px 20px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.5s;
}
.cases-categories__name {
  font-size: 14px;
  line-height: 1.2;
  color: #4a4a4a;
}
.cases-categories__image {
  margin-right: 15px;
  margin-top: -2px;
}
.cases-categories__item--active {
  background-color: @red_main;
  border: 1px solid @red_main;
  .cases-categories__name {
    color: @white;
  }
  &::after {
    display: block;
    content: '';
    width: 25px;
    height: 25px;
    background: url(../media/img/reviews-and-results/check.svg) no-repeat center center;
    background-size: cover;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}
.cases-categories__arrow-wrapper {
  display: none;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;
}

.cases-categories__button-wrapper {
  padding: 10px 15px 20px;
  display: none;
}
.cases-categories__button {
  border: 1px solid #d7ccdb;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
}


section.cases-list {
  padding: 50px 15px;
  background-color: @white;
}



@media only screen and (min-width: 992px) {
  .cases-categories__item:hover {
    border-color: @red_main;
  }
}


@media only screen and (max-width: 991px) {
  section.cases-categories {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .cases-categories__button-wrapper {
    display: block;
  }
  .cases-categories__arrow-wrapper {
    display: block;
  }
  section.cases-categories {
    opacity: 0;
    visibility: hidden;
    background-color: #f5f3f6;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    overflow-y: auto;
    height: 100vh;
    transition: all 0.5s;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  .cases-categories__item {
    height: 50px;
    max-width: 100%;
    width: 100%;
  }
  section.cases-list {
    padding: 40px 15px;
  }
}