@import "structure/variables";

section.page-banner {
  padding-bottom: 140px;
  position: relative;
  .container {
    z-index: 1;
  }
}

.page-banner__image-wrapper {
  max-width: 755px;
  position: relative;
  display: inline-block;

}

.page-banner-wrapper {
  max-width: 620px;
  margin-left: auto;
  margin-top: -140px;
  position: relative;
  z-index: 1;
}

.page-banner__title {
  font-size: 44px;
  color: #000000;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
  .red {
    font-size: 36px;
    font-weight: 400;
    color: @red_main;
  }
  .small {
    text-transform: none;
    max-width: 580px;
  }
}

.page-banner__text {
  color: #544b5c;
  line-height: 1.5;
}

.not-found {
  margin: 160px 0;
  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 40%;
      margin: 0 20px;
    }
  }
  &__text {
    font-family: ProximaNova;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.41;
    color: #ff0048;
    margin-top: 50px;
    text-transform: uppercase;
    text-align: center;
  }
  &__link {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    font-family: ProximaNova;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    svg {
      margin-right: 10px;
    }
  }
}

.page-wrap--second-page {
  background-color: #f5f3f6;
  /*header {
    background-color: #f5f3f6;
  }*/
}

.page-wrap--third-page {
  background-color: #f5f3f6;
  /*header {
    background-color: #f5f3f6;
  }*/
  .page-banner {
    .container {
      position: relative;
    }
  }
  .page-banner-wrapper {
    max-width: 450px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin: 0;
  }
}

@import "results-and-reviews/results_and_reviews";
@import "results-and-reviews/cases";
@import "results-and-reviews/case";
@import "results-and-reviews/reviews-all";
@import "services/services";
@import "services/development";
@import "services/marketing";
@import "services/service";
@import "approach/approach";
@import "approach/marketing-approach";
@import "approach/unique-practice";
@import "company/company";
@import "company/about-company";
@import "company/contacts";
@import "company/vacancies";
@import "company/vacancy";
@import "company/faq";
@import "blog/blog";
@import "blog/post";

@media only screen and (max-width: 1199px) {
  .page-wrap--third-page {
    section.page-banner {
      padding-bottom: 80px;
    }
    .page-banner__image-wrapper {
      max-width: 500px;
    }
    .page-banner-wrapper {
      max-width: 420px;
    }
  }
}

@media only screen and (max-width: 991px) {
  section.page-banner {
    padding-bottom: 50px;
  }

  .page-banner-wrapper {
    margin-top: 15px;
  }

  .page-wrap--third-page {
    section.page-banner {
      padding-bottom: 60px;
      .container {
        display: flex;
        justify-content: space-between;
      }
    }
    .page-banner-wrapper {
      position: relative;
      top: 0;
      max-width: 350px;
      transform: none;
    }
    .page-banner__image-wrapper {
      max-width: 310px;

      img {
        min-height: 100%;
        object-fit: cover;
      }
    }
    .page-banner__title {
      font-size: 34px;
      .red {
        font-size: 26px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {

  .page-banner__title {
    font-size: 30px;
    .red {
      font-size: 24px;
    }
  }

  .page-wrap--third-page {
    section.page-banner {
      .container {
        flex-direction: column;
      }
    }
    .page-banner-wrapper {
      max-width: 100%;
    }
    .page-banner__image-wrapper {
      max-width: 100%;
      margin-bottom: 15px;
    }
    .page-banner__title {
      font-size: 30px;
      .red {
        font-size: 24px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .page-wrap--third-page {
    section.page-banner {
      padding-bottom: 40px;
    }
  }


}

@import "structure/customer-acquisition";