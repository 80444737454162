@import "../structure/variables";

.company-numbers_acquisition {
  background: @white;

  .company-numbers__wrapper {
    margin: 0px auto 0;
    transform: translateY(-100px);
  }
}

.services-list-general {
  background-color: @white;
  padding: 65px 0 0;
  .company__link--video {
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: -31px;
  }
}

.services-list-row {
  align-items: flex-start;
}

.services-list-general__title {
  font-size: 44px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  padding-top: 50px;
}

.services-list-general__subtitle {
  font-size: 36px;
  color: @red_main;
  line-height: 1;
  margin-bottom: 40px;
}

.services-list-general__text {
  line-height: 1.6;
  padding-bottom: 20px;
}

.services-list-general__image {
  //margin-bottom: -115px;
  margin-bottom: -50px;
}

.services-list-general__image--development {
  margin-left: auto;
}

section.services-list {
  padding: 120px 0 55px;

}

section.services-list.services-list_restautant {
  padding: 96px 0;

}

.development-list {
  background-color: #fde4e7;
}

.marketing-list {
  background-color: #efebf0;
}

.services-list_acquisition {
  padding: 75px 0;
  .container {
    max-width: 940px;
  }
}

.services-list__title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 90px;
  text-align: center;

  &_acquisition {
    position: relative;
    margin-bottom: 50px;
    font-size: 16px;

    &:before {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 54px;
      height: 2px;
      background: @red_main;
    }
  }
}

.service-list__text {
  width: 100%;
  max-width: 415px;
  margin: 0 auto 43px;

  font-size: 14px;
  font-weight: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

.services-list__item {
  padding-left: 90px;
  position: relative;
  margin-bottom: 40px;
}

.services-list__name {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.43;
  margin-bottom: 10px;
  transition: all 0.5s;
}

.services-list__text {
  line-height: 1.5;
  color: #544b5c;
}

.services-list__image {
  position: absolute;
  left: 0;
  top: 0px;
  width: 65px;
  max-height: 65px;
  object-fit: contain;
  transition: all 0.5s;
}

.services-list__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.services {
  &__more {
    text-align: right;
  }
  &__link {
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    color: #544b5c;
    text-transform: uppercase;
    span {
      vertical-align: middle;
      margin-right: 5px;
    }
    svg {
      display: inline-block;
      vertical-align: middle;
      transition: all 0.5s;
    }
    &:hover svg {
      transform: translateX(10px);
    }
  }
}

.callback-form {
  background: #ffffff;
  padding: 75px 0;
  text-align: center;

  &__title {
    position: relative;
    margin-bottom: 50px;
    font-family: 'ProximaNova', sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    text-transform: uppercase;

    &:before {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 54px;
      height: 2px;
      background: @red_main;
    }
  }

  &__text {
    max-width: 450px;
    margin: 0 auto;
    font-family: 'ProximaNova', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    text-align: center;
    color: #4a4a4a;
  }

  &__form {
    margin-top: 30px;
  }

  &__field {
    position: relative;
    width: 342px;
    height: 40px;
    margin: 0 auto 10px;
    padding-top: 20px;

    input {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 20px;
      padding: 0 3px 3px;
      border: none;
      background: transparent;
      border-bottom: 1px solid #efefef;
      transiton: 0.25s ease-in-out;

      &:focus ~ label,
      &:active ~ label,
      &.filled ~ label {
        bottom: 20px;

        font-size: 12px;
        color: #c8c8c8;

        transition: 0.25s ease;
      }

      &:focus,
      &:active {
        border-color: @red_main;
        transiton: 0.25s ease-in-out;
      }
    }
    label {
      z-index: 0;
      position: absolute;
      bottom: 5px;
      left: 3px;
      height: 19px;
      margin: 0;
      font-family: 'ProximaNova', sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #4a4a4a;
      transition: 0.25s ease;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 232px;
    height: 46px;
    margin: 30px auto 0;
    padding: 0;
    text-align: center;

    span {
      line-height: 14px;
      padding: 0;
    }
  }

}

.company_customer {
  img {
    right: unset;
    left: 20px;
  }

  .company__text {
    font-family: 'ProximaNova';
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .company__text-container {
    padding-left: 115px;
  }

}

.service-result_customer {
  ul.service-result__text {
    li {
      position: relative;
      padding-left: 35px;
      margin-bottom: 30px;
      list-style: none;

      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4a4a4a;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FF1449;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M437,75C388.7,26.6,324.4,0,256,0C187.6,0,123.3,26.6,75,75C26.6,123.3,0,187.6,0,256s26.6,132.7,75,181 s112.6,75,181,75c68.4,0,132.7-26.6,181-75s75-112.6,75-181S485.4,123.3,437,75z M256,482C131.4,482,30,380.6,30,256 S131.4,30,256,30s226,101.4,226,226S380.6,482,256,482z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M378.3,173.9c-5.9-5.9-15.4-5.9-21.2,0L224.6,306.3l-69.7-69.7c-5.9-5.9-15.4-5.9-21.2,0s-5.9,15.4,0,21.2 l80.3,80.3c2.9,2.9,6.8,4.4,10.6,4.4s7.7-1.5,10.6-4.4l143.1-143.1C384.2,189.2,384.2,179.7,378.3,173.9z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
        background-size: contain;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.service-result-white {
  max-width: 700px;
  margin: 48px auto 0;
  padding: 36px 48px;
  background: @white;

  .grey-bg__text {
    margin-bottom: 24px;
    padding-left: 35px;
  }
}

.page-banner {
  .container {
    position: relative;
  }
}

@media only screen and (min-width: 992px) {

  .services-list__item:hover {
    .services-list__name {
      color: @red_main;
    }
    .services-list__image {
      transform: scale(1.2);
    }
  }
}

@media only screen and (max-width: 991px) {
  .services-list-general {
    padding: 60px 0;
  }

  .services-list-row {
    align-items: center;
  }

  .services-list-general__title {
    padding: 0;
  }

  .services-list-general__title {
    font-size: 34px;
  }

  .services-list-general__subtitle {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .services-list-general__text {
    line-height: 1.5;
    font-size: 14px;
    padding-bottom: 0;
  }

  .services-list-general__image {
    margin: 0;
  }

  section.services-list {
    padding: 50px 0 20px;
  }

  .services-list__image {
    width: 50px;
  }

  .services-list__item {
    padding-left: 70px;
  }

  .services-list__text {
    font-size: 14px;
  }

  .services-list__title {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .services-list-general {
    padding: 40px 0;
  }

  .services-list-general__image {
    margin-bottom: 10px;
  }

  section.services-list {
    padding: 40px 0 20px;
  }

  section.services-list.services-list_restautant {
    padding: 60px 0 0px;
  }

  .services-list__item {
    margin-bottom: 50px;
  }

  .services-list__title {
    font-size: 20px;
    margin-bottom: 50px;
  }

}

@media only screen and (max-width: 575px) {
  .callback-form {
    padding: 40px 0;
  }

  .service-result-white {
    margin: 36px auto 0;
    padding: 24px 15px;
  }
}