@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse_company {
  to {
    box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.15);
  }
}
@keyframes upButtPulse {
  0 {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 40px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes downButtPulse {
  0,
  85%,
  100% {
    transform: translate(0, 0);
  }
  80% {
    transform: translate(-10px, 0);
  }
  90% {
    transform: translate(10px, 0);
  }
}
section.page-banner {
  padding-bottom: 140px;
  position: relative;
}
section.page-banner .container {
  z-index: 1;
}
.page-banner__image-wrapper {
  max-width: 755px;
  position: relative;
  display: inline-block;
}
.page-banner-wrapper {
  max-width: 620px;
  margin-left: auto;
  margin-top: -140px;
  position: relative;
  z-index: 1;
}
.page-banner__title {
  font-size: 44px;
  color: #000000;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.page-banner__title .red {
  font-size: 36px;
  font-weight: 400;
  color: #ff0048;
}
.page-banner__title .small {
  text-transform: none;
  max-width: 580px;
}
.page-banner__text {
  color: #544b5c;
  line-height: 1.5;
}
.not-found {
  margin: 160px 0;
}
.not-found__img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found__img img {
  max-width: 40%;
  margin: 0 20px;
}
.not-found__text {
  font-family: ProximaNova;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  color: #ff0048;
  margin-top: 50px;
  text-transform: uppercase;
  text-align: center;
}
.not-found__link {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  font-family: ProximaNova;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}
.not-found__link svg {
  margin-right: 10px;
}
.page-wrap--second-page {
  background-color: #f5f3f6;
  /*header {
    background-color: #f5f3f6;
  }*/
}
.page-wrap--third-page {
  background-color: #f5f3f6;
  /*header {
    background-color: #f5f3f6;
  }*/
}
.page-wrap--third-page .page-banner .container {
  position: relative;
}
.page-wrap--third-page .page-banner-wrapper {
  max-width: 450px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0;
}
.section-title--new-works {
  margin-bottom: 40px;
}
section.works-list {
  padding: 100px 15px 20px;
  background-color: #fff;
}
section.works-list--home {
  padding-top: 100px;
}
.works-list__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25;
  text-transform: uppercase;
  margin-bottom: 45px;
  text-align: center;
}
.works-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 960px;
  margin: 0 auto;
}
.work-card-wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 20px;
  transition: opacity 1s ease, transform 1s;
  margin-bottom: 40px;
  max-height: 336px;
}
.work-card-wrapper.animate {
  opacity: 0;
  transform: translateY(100px);
}
.work-card {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.work-card .work-card__image {
  transition: transform 1s;
  height: 100%;
  object-fit: cover;
}
.work-card:hover .work-card__image {
  transform: scale(1.1);
}
.work-card__info {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 0, 72, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.work-card__title {
  font-size: 20px;
  color: #fff;
  font-weight: 800;
  line-height: 1.3;
  margin-bottom: 10px;
}
.work-card__text {
  line-height: 1.25;
  max-width: 270px;
  color: #fff;
}
.work-card__arrow {
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
}
.work-card__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.works-list__button-wrapper {
  text-align: center;
}
/*.works-list__button {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  color: #544b5c;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 50px 15px;
  min-width: 230px;
  border-radius: 100px;
  background-color: rgba(84, 75, 92, 0.15);
  transition: all 0.5s;
}*/
@media only screen and (min-width: 992px) {
  .work-card__info:hover {
    opacity: 1;
  }
  .works-list__button:hover {
    color: #ff0048;
  }
}
@media only screen and (max-width: 991px) {
  .work-card__info {
    opacity: 1;
  }
}
@media only screen and (max-width: 767px) {
  section.works-list {
    padding: 40px 15px;
  }
  .work-card-wrapper {
    flex: auto;
    max-width: 100%;
    padding: 0;
  }
  .work-card-wrapper {
    margin-bottom: 30px;
  }
  .work-card-wrapper .work-card__image {
    height: auto;
  }
}
section.cases-categories {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}
.cases-categories__item {
  width: 100%;
  max-width: 300px;
  height: 60px;
  padding: 5px 50px 5px 20px;
  border: solid 1px #d7ccdb;
  margin: 0 10px 20px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.5s;
}
.cases-categories__name {
  font-size: 14px;
  line-height: 1.2;
  color: #4a4a4a;
}
.cases-categories__image {
  margin-right: 15px;
  margin-top: -2px;
}
.cases-categories__item--active {
  background-color: #ff0048;
  border: 1px solid #ff0048;
}
.cases-categories__item--active .cases-categories__name {
  color: #fff;
}
.cases-categories__item--active::after {
  display: block;
  content: '';
  width: 25px;
  height: 25px;
  background: url(../media/img/reviews-and-results/check.svg) no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
.cases-categories__arrow-wrapper {
  display: none;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;
}
.cases-categories__button-wrapper {
  padding: 10px 15px 20px;
  display: none;
}
.cases-categories__button {
  border: 1px solid #d7ccdb;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
}
section.cases-list {
  padding: 50px 15px;
  background-color: #fff;
}
@media only screen and (min-width: 992px) {
  .cases-categories__item:hover {
    border-color: #ff0048;
  }
}
@media only screen and (max-width: 991px) {
  section.cases-categories {
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  .cases-categories__button-wrapper {
    display: block;
  }
  .cases-categories__arrow-wrapper {
    display: block;
  }
  section.cases-categories {
    opacity: 0;
    visibility: hidden;
    background-color: #f5f3f6;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    overflow-y: auto;
    height: 100vh;
    transition: all 0.5s;
  }
  section.cases-categories.active {
    opacity: 1;
    visibility: visible;
  }
  .cases-categories__item {
    height: 50px;
    max-width: 100%;
    width: 100%;
  }
  section.cases-list {
    padding: 40px 15px;
  }
}
.case-header_wrap {
  padding: 50px 0 90px;
}
.case-header_wrap .page-header {
  color: #000;
  border: none;
  padding-bottom: 9px;
  margin: 40px 0 20px;
  width: 100%;
}
.case-header_wrap .page-header .page-title {
  font-size: 36px;
  font-family: ProximaNova, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}
.case-header_wrap .page-header .page-subtitle {
  font-family: ProximaNova, sans-serif;
  font-weight: bold;
}
.case-header_wrap .page-header .description {
  margin-top: 30px;
}
.case-header_wrap .page-header .description ul {
  margin-left: 20px;
}
.case-header_wrap .page-header .what-have-done {
  margin-top: 80px;
  font-size: 14px;
}
.case-header_wrap .page-header .what-have-done ul {
  margin-left: 20px;
}
.case-header_wrap .page-header .title {
  font-family: ProximaNova, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-size: 14px;
}
.case-header_wrap .page-header a {
  text-decoration: underline;
  color: #000;
}
.case-result_wrap {
  padding: 65px 0 30px;
  padding-top: 0;
}
.case-result_wrap .browser-wrapper {
  position: relative;
  margin-top: -90px;
  padding-bottom: 80px;
  border: 2px solid #d8d8d8;
  border-top: 27px solid #d8d8d8;
  background: #fff;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.case-result_wrap .browser-wrapper:before {
  content: "\2022 \2022 \2022";
  display: block;
  position: absolute;
  left: 3px;
  top: -30px;
  font-size: 50px;
  line-height: 27px;
  color: #ffffff;
}
.case-result_wrap .browser-wrapper .section-title-wrap {
  margin-bottom: 65px;
}
.case-result_wrap .browser-wrapper .section-title-wrap .section-title {
  font-family: ProximaNova;
  font-weight: bold;
  margin-top: 40px;
}
.case-result_wrap .browser-wrapper .block-info {
  max-width: 350px;
  margin: 0 auto 30px;
}
.case-result_wrap .browser-wrapper .block-info .radial-progress-holder {
  padding: 25px 0;
}
.case-result_wrap .browser-wrapper .block-info .radial-progress {
  margin: 0 0 0 10px;
  width: 160px;
  height: 160px;
  background-color: #ededed;
  border-radius: 50%;
  margin: 0 auto;
}
.case-result_wrap .browser-wrapper .block-info .radial-progress .circle .mask {
  clip: rect(0px, 160px, 160px, 80px);
}
.case-result_wrap .browser-wrapper .block-info .radial-progress .circle .mask .fill {
  clip: rect(0px, 80px, 160px, 0px);
  background-color: #ff3548;
}
.case-result_wrap .browser-wrapper .block-info .radial-progress .circle .shadow {
  width: 160px;
  height: 160px;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.case-result_wrap .browser-wrapper .block-info .inset {
  width: 145px;
  height: 145px;
  position: absolute;
  margin-left: 7.5px;
  margin-top: 7.5px;
  background-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.case-result_wrap .browser-wrapper .block-info .inset .percentage {
  height: 44px;
  width: 190px;
  overflow: hidden;
  position: absolute;
  top: 50.5px;
  left: -22.5px;
  line-height: 1;
}
.case-result_wrap .browser-wrapper .block-info .inset .percentage .number span {
  width: 190px;
  display: block;
  vertical-align: top;
  text-align: center;
  font-size: 44px;
  font-family: ProximaNova, sans-serif;
  font-weight: bold;
  color: #544A5C;
}
.case-result_wrap .browser-wrapper .block-info .radial-progress-holder {
  padding: 25px 0;
}
.case-result_wrap .browser-wrapper .block-info .circle-with-info {
  display: block;
  margin: 0 auto;
  width: 160px;
  height: 160px;
  color: #fff;
  background: #ff3548;
  font-family: ProximaNova, sans-serif;
  font-weight: bold;
  font-size: 44px;
  line-height: 160px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  overflow: hidden;
}
.case-result_wrap .browser-wrapper .block-info .circle-with-info .value img {
  margin: 35px auto;
}
.case-screens_wrap {
  padding: 65px 0 30px;
  padding-bottom: 0;
}
.case-screens_wrap .section-content {
  padding: 50px 0;
}
.case-screens_wrap .image-holder {
  padding: 50px 0;
}
.case-screens_wrap .image-holder.no-padding {
  padding: 0;
}
.case-screens_wrap .image-holder.no-bg {
  background: none;
}
.case-screens_wrap .image-holder img {
  max-width: 96%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}
.case-screens_wrap .image-holder .video_wrapper video {
  max-width: 100%;
}
.case-screens_wrap .icons-list-wrap {
  background: #ededed;
  padding: 50px 0;
}
.case-screens_wrap .icons-list-wrap ul {
  padding: 0;
  margin: 0;
}
.case-screens_wrap .icons-list-wrap li {
  list-style: none;
  display: inline-block;
  padding: 20px 10px;
  width: 19%;
  margin-bottom: 10px;
  text-align: center;
}
.case-screens_wrap .icons-list-wrap li img {
  max-width: 100px;
}
.case-next_wrap {
  padding: 100px 0;
  background: rgba(0, 0, 0, 0.66);
  color: #ffffff;
  position: relative;
  background-position: center center;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
}
.case-next_wrap:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
}
.case-next_wrap .next-client {
  position: relative;
  z-index: 1;
  text-align: center;
}
.case-next_wrap .next-client .title {
  font-size: 36px;
  font-family: ProximaNova;
  font-weight: bold;
  margin-bottom: 20px;
}
.case-next_wrap .next-client .link-holder a {
  color: #fff;
  font-size: 24px;
  padding-bottom: 30px;
}
.case-next_wrap .next-client .link-holder a:hover {
  color: #ff3548;
}
.case-next_wrap .next-client .link-holder a:after {
  content: "";
  width: 30px;
  height: 20px;
  background: url(/assets/media/img/small-arrow-right-red.svg) no-repeat;
  display: block;
  margin: 10px auto 0;
}
.case-custom-results__wrapper {
  margin: -140px auto 0;
  padding: 70px 90px 0;
  width: 100%;
  max-width: 1120px;
  object-fit: contain;
  border: solid 2px #e1e1e1;
  background-color: #ffffff;
}
.case-custom-results .section-title {
  margin-bottom: 70px;
  font-size: 26px;
  font-weight: bold;
  line-height: 0.92;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.faradey_animation {
  position: relative;
  padding: 50px 0;
}
.faradey_animation .faradey_bolt {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.faradey_animation img {
  max-width: 100%;
}
.pulse {
  animation: sk-scaleout 1.2s infinite ease-in-out;
}
.video_wrapper.vg_video {
  text-align: center;
  font-size: 0;
}
@media only screen and (max-width: 1200px) {
  .case-custom-results__wrapper {
    padding: 50px 70px 0;
    max-width: 970px;
    width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .case-custom-results__wrapper {
    padding: 50px 50px 0;
    width: 100%;
    max-width: 767px;
  }
  .case-custom-results .section-title {
    margin-bottom: 40px;
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .case-screens_wrap {
    padding-top: 0;
  }
  .case-screens_wrap .image-holder img {
    max-width: 100% !important;
  }
  .what-have-done ul {
    margin-left: 15px;
  }
  .case-result_wrap .browser-wrapper {
    border: none;
    margin-top: 0;
    padding-bottom: 0;
  }
  .case-result_wrap .browser-wrapper:before {
    display: none;
  }
  .case-screens_wrap .icons-list-wrap ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .case-screens_wrap .icons-list-wrap ul li {
    width: 50%;
    padding: 0;
  }
  .case-screens_wrap .icons-list-wrap ul li img {
    margin: 0 auto;
  }
  .case-custom-results__wrapper {
    margin: -90px auto 0;
    padding: 50px 20px 0;
    width: 100%;
    max-width: 575px;
  }
  .case-custom-results .section-title {
    margin-bottom: 40px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .case-custom-results {
    padding: 15px;
  }
  .case-custom-results__wrapper {
    margin: 0px auto 0;
    padding: 50px 20px 0;
    width: 100%;
    max-width: 480px;
  }
  .case-custom-results .section-title {
    margin-bottom: 40px;
    font-size: 20px;
  }
}
section.reviews-main {
  padding: 60px 0;
}
.reviews-main__title {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: #000000;
}
.reviews-main__subtitle {
  max-width: 580px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #544b5c;
}
section.reviews-list {
  padding: 0 15px 90px;
  max-width: 1230px;
  margin: 0 auto;
}
section.reviews-list .reviews-item {
  margin-bottom: 20px;
  padding: 0;
}
section.reviews-list .reviews-item-wrapper {
  margin: 0 auto;
  padding: 30px 30px 70px 90px;
}
section.reviews-list .reviews-item__info {
  padding-right: 35px;
}
section.reviews-list .reviews-item__photo {
  margin-bottom: 40px;
}
section.reviews-list .reviews-item__hidden-text {
  position: relative;
  display: block;
  max-height: 255px;
  overflow: hidden;
  transition: 0.25s ease;
}
section.reviews-list .reviews-item__hidden-text:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.21), #ffffff 100%);
  opacity: 1;
  visibility: visible;
  transition: 0.25s ease;
}
section.reviews-list .reviews-item__hidden-text.open {
  max-height: 9999px;
  transition: 0.25s ease;
}
section.reviews-list .reviews-item__hidden-text.open:after {
  opacity: 0;
  visibility: hidden;
  transition: 0.25s ease;
}
section.reviews-list .reviews-item__button {
  margin-top: 0px;
}
section.reviews-list .reviews-item__button svg {
  display: block;
  margin: 0 auto;
  width: 28px;
  height: 15px;
}
section.reviews-list .reviews-item__quote {
  margin-top: 25px;
}
.reviews-list__button-wrapper {
  text-align: center;
}
.reviews-list__button {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: #544b5c;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 25px 15px;
  border: 1px solid #000000;
  border-radius: 100px;
  min-width: 230px;
  transition: all 0.5s;
  cursor: pointer;
}
@media only screen and (min-width: 992px) {
  .reviews-list__button:hover {
    background-color: #000000;
    color: #fff;
  }
}
@media only screen and (max-width: 991px) {
  section.reviews-main {
    padding: 20px 0 40px;
  }
  .reviews-main__title {
    font-size: 22px;
  }
  section.reviews-list {
    padding: 0 15px 60px;
  }
  section.reviews-list .reviews-item-wrapper {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .reviews-main__title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .reviews-main__subtitle {
    font-size: 16px;
  }
  section.reviews-list .reviews-item-wrapper {
    flex-direction: column;
  }
  section.reviews-list .reviews-item__text {
    display: block;
    flex: auto;
    max-width: 100%;
    text-align: center;
  }
  section.reviews-list .reviews-item__hidden-text {
    max-height: 175px;
  }
}
.company-numbers_acquisition {
  background: #fff;
}
.company-numbers_acquisition .company-numbers__wrapper {
  margin: 0px auto 0;
  transform: translateY(-100px);
}
.services-list-general {
  background-color: #fff;
  padding: 65px 0 0;
}
.services-list-general .company__link--video {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -31px;
}
.services-list-row {
  align-items: flex-start;
}
.services-list-general__title {
  font-size: 44px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  padding-top: 50px;
}
.services-list-general__subtitle {
  font-size: 36px;
  color: #ff0048;
  line-height: 1;
  margin-bottom: 40px;
}
.services-list-general__text {
  line-height: 1.6;
  padding-bottom: 20px;
}
.services-list-general__image {
  margin-bottom: -50px;
}
.services-list-general__image--development {
  margin-left: auto;
}
section.services-list {
  padding: 120px 0 55px;
}
section.services-list.services-list_restautant {
  padding: 96px 0;
}
.development-list {
  background-color: #fde4e7;
}
.marketing-list {
  background-color: #efebf0;
}
.services-list_acquisition {
  padding: 75px 0;
}
.services-list_acquisition .container {
  max-width: 940px;
}
.services-list__title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 90px;
  text-align: center;
}
.services-list__title_acquisition {
  position: relative;
  margin-bottom: 50px;
  font-size: 16px;
}
.services-list__title_acquisition:before {
  content: '';
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 54px;
  height: 2px;
  background: #ff0048;
}
.service-list__text {
  width: 100%;
  max-width: 415px;
  margin: 0 auto 43px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}
.services-list__item {
  padding-left: 90px;
  position: relative;
  margin-bottom: 40px;
}
.services-list__name {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.43;
  margin-bottom: 10px;
  transition: all 0.5s;
}
.services-list__text {
  line-height: 1.5;
  color: #544b5c;
}
.services-list__image {
  position: absolute;
  left: 0;
  top: 0px;
  width: 65px;
  max-height: 65px;
  object-fit: contain;
  transition: all 0.5s;
}
.services-list__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.services__more {
  text-align: right;
}
.services__link {
  display: inline-block;
  font-size: 12px;
  font-weight: 800;
  color: #544b5c;
  text-transform: uppercase;
}
.services__link span {
  vertical-align: middle;
  margin-right: 5px;
}
.services__link svg {
  display: inline-block;
  vertical-align: middle;
  transition: all 0.5s;
}
.services__link:hover svg {
  transform: translateX(10px);
}
.callback-form {
  background: #ffffff;
  padding: 75px 0;
  text-align: center;
}
.callback-form__title {
  position: relative;
  margin-bottom: 50px;
  font-family: 'ProximaNova', sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}
.callback-form__title:before {
  content: '';
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 54px;
  height: 2px;
  background: #ff0048;
}
.callback-form__text {
  max-width: 450px;
  margin: 0 auto;
  font-family: 'ProximaNova', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.38;
  text-align: center;
  color: #4a4a4a;
}
.callback-form__form {
  margin-top: 30px;
}
.callback-form__field {
  position: relative;
  width: 342px;
  height: 40px;
  margin: 0 auto 10px;
  padding-top: 20px;
}
.callback-form__field input {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 20px;
  padding: 0 3px 3px;
  border: none;
  background: transparent;
  border-bottom: 1px solid #efefef;
  transiton: 0.25s ease-in-out;
}
.callback-form__field input:focus ~ label,
.callback-form__field input:active ~ label,
.callback-form__field input.filled ~ label {
  bottom: 20px;
  font-size: 12px;
  color: #c8c8c8;
  transition: 0.25s ease;
}
.callback-form__field input:focus,
.callback-form__field input:active {
  border-color: #ff0048;
  transiton: 0.25s ease-in-out;
}
.callback-form__field label {
  z-index: 0;
  position: absolute;
  bottom: 5px;
  left: 3px;
  height: 19px;
  margin: 0;
  font-family: 'ProximaNova', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #4a4a4a;
  transition: 0.25s ease;
}
.callback-form__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232px;
  height: 46px;
  margin: 30px auto 0;
  padding: 0;
  text-align: center;
}
.callback-form__submit span {
  line-height: 14px;
  padding: 0;
}
.company_customer img {
  right: unset;
  left: 20px;
}
.company_customer .company__text {
  font-family: 'ProximaNova';
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.company_customer .company__text-container {
  padding-left: 115px;
}
.service-result_customer ul.service-result__text li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  list-style: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4a4a4a;
}
.service-result_customer ul.service-result__text li:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FF1449;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M437,75C388.7,26.6,324.4,0,256,0C187.6,0,123.3,26.6,75,75C26.6,123.3,0,187.6,0,256s26.6,132.7,75,181 s112.6,75,181,75c68.4,0,132.7-26.6,181-75s75-112.6,75-181S485.4,123.3,437,75z M256,482C131.4,482,30,380.6,30,256 S131.4,30,256,30s226,101.4,226,226S380.6,482,256,482z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M378.3,173.9c-5.9-5.9-15.4-5.9-21.2,0L224.6,306.3l-69.7-69.7c-5.9-5.9-15.4-5.9-21.2,0s-5.9,15.4,0,21.2 l80.3,80.3c2.9,2.9,6.8,4.4,10.6,4.4s7.7-1.5,10.6-4.4l143.1-143.1C384.2,189.2,384.2,179.7,378.3,173.9z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
  background-size: contain;
}
.service-result_customer ul.service-result__text li:last-child {
  margin-bottom: 0;
}
.service-result-white {
  max-width: 700px;
  margin: 48px auto 0;
  padding: 36px 48px;
  background: #fff;
}
.service-result-white .grey-bg__text {
  margin-bottom: 24px;
  padding-left: 35px;
}
.page-banner .container {
  position: relative;
}
@media only screen and (min-width: 992px) {
  .services-list__item:hover .services-list__name {
    color: #ff0048;
  }
  .services-list__item:hover .services-list__image {
    transform: scale(1.2);
  }
}
@media only screen and (max-width: 991px) {
  .services-list-general {
    padding: 60px 0;
  }
  .services-list-row {
    align-items: center;
  }
  .services-list-general__title {
    padding: 0;
  }
  .services-list-general__title {
    font-size: 34px;
  }
  .services-list-general__subtitle {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .services-list-general__text {
    line-height: 1.5;
    font-size: 14px;
    padding-bottom: 0;
  }
  .services-list-general__image {
    margin: 0;
  }
  section.services-list {
    padding: 50px 0 20px;
  }
  .services-list__image {
    width: 50px;
  }
  .services-list__item {
    padding-left: 70px;
  }
  .services-list__text {
    font-size: 14px;
  }
  .services-list__title {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .services-list-general {
    padding: 40px 0;
  }
  .services-list-general__image {
    margin-bottom: 10px;
  }
  section.services-list {
    padding: 40px 0 20px;
  }
  section.services-list.services-list_restautant {
    padding: 60px 0 0px;
  }
  .services-list__item {
    margin-bottom: 50px;
  }
  .services-list__title {
    font-size: 20px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .callback-form {
    padding: 40px 0;
  }
  .service-result-white {
    margin: 36px auto 0;
    padding: 24px 15px;
  }
}
.page-wrap--development-page {
  background-color: #f5f3f6;
}
.page-wrap--development-page .development-list {
  background-color: #fff;
  padding-top: 70px;
}
.page-wrap--development-page .practice-list {
  background-color: #f4f4f4;
}
.page-wrap--development-page .practice-list::after {
  background-color: #f4f4f4;
}
.page-wrap--development-page section.works {
  background-color: #f4f4f4;
}
.page-wrap--development-page .works-button-wrapper {
  display: none;
}
.page-wrap--development-page .works-list-wrapper {
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 992px) {
  .page-wrap--development-page .practice-list__item:hover {
    background-color: #fff;
  }
}
@media only screen and (max-width: 991px) {
  .page-wrap--development-page .development-list {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .page-wrap--development-page .development-list {
    padding-top: 40px;
  }
}
.page-wrap--marketing-page {
  background-color: #f5f3f6;
}
.page-wrap--marketing-page .marketing-list {
  background-color: #fff;
  padding-top: 70px;
  padding-bottom: 70px;
}
.page-wrap--marketing-page .practice-list {
  background-color: #f4f4f4;
}
.page-wrap--marketing-page .practice-list::after {
  background-color: #f4f4f4;
}
.page-wrap--marketing-page section.works {
  background-color: #f4f4f4;
}
.page-wrap--marketing-page .works-button-wrapper {
  display: none;
}
.page-wrap--marketing-page .works-list-wrapper {
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 992px) {
  .page-wrap--marketing-page .practice-list__item:hover {
    background-color: #fff;
  }
}
@media only screen and (max-width: 991px) {
  .page-wrap--marketing-page .marketing-list {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .page-wrap--marketing-page .marketing-list {
    padding-top: 40px;
  }
}
.page-wrap--service-page {
  background-color: #f5f3f6;
}
.page-wrap--service-page section.works-title {
  font-family: ProximaNova;
  font-size: 22px;
  font-weight: bold;
  line-height: 120px;
  text-align: center;
  color: #000000;
  position: relative;
  z-index: 2;
  margin-bottom: -200px;
  padding-top: 50px;
  text-transform: uppercase;
}
.page-wrap--service-page section.works {
  margin-top: -30px;
}
.page-wrap--service-page section.works .works-slider .works-item {
  padding-top: 200px;
}
.page-wrap--service-page .works-list-wrapper {
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}
section.service-tabs {
  background-color: #fff;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}
.service-tabs-nav {
  border-bottom: solid 1px rgba(155, 155, 155, 0.23);
}
.service-tabs-nav__wrapper {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-tabs-nav__item {
  padding: 40px 15px 30px;
  cursor: pointer;
  position: relative;
}
.service-tabs-nav__item--active::after,
.service-tabs-nav__item:hover::after {
  display: block;
  content: '';
  width: 100%;
  height: 5px;
  background-color: #ff0048;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.5s;
}
.service-tabs-wrapper {
  padding: 70px 100px 0;
}
.service-tabs__item {
  display: none;
  align-items: flex-start;
}
.service-tabs__item--active {
  display: flex;
}
.service-tabs__item-image-wrapper {
  flex: 0 0 340px;
  max-width: 340px;
  height: 340px;
  background-color: rgba(255, 0, 72, 0.1);
  border-radius: 50%;
  margin-right: 40px;
  position: relative;
}
.service-tabs__item-image {
  position: absolute;
}
.service-tabs__item-text {
  flex: 1;
  color: #4a4a4a;
  line-height: 1.5;
}
.service-tabs__item-text li {
  list-style-type: none;
}
.service-tabs__item-text p {
  margin-bottom: 10px;
}
.service-tabs__item-title {
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding-top: 10px;
  color: #000;
}
section.service-special {
  background-color: #fff;
  padding: 100px 0;
}
.service-special_wrapper {
  max-width: 720px;
  margin: 0 auto;
  padding-left: 100px;
}
.service-special__title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
  line-height: 1;
}
.service-special__text {
  color: #4a4a4a;
  line-height: 1.5;
}
section.service-list {
  background-color: #fff;
  padding: 0 15px;
}
.service-list-row {
  max-width: 960px;
  margin: 0 auto;
  padding: 50px 0 130px;
  position: relative;
  display: flex;
}
.service-list-row:first-child:not(.not-connections)::before {
  display: block;
  content: '';
  width: 1px;
  height: calc(100% - 300px + 70px);
  background-color: #ff0048;
  position: absolute;
  top: 300px;
  right: 400px;
  z-index: 2;
}
.service-list-row:first-child:not(.not-connections)::after {
  display: block;
  content: '';
  width: 11px;
  height: 11px;
  background-color: #ff0048;
  border-radius: 50%;
  position: absolute;
  bottom: -80px;
  right: 395px;
  z-index: 3;
}
.service-list-row:first-child .service-list-row__number {
  top: -50px;
}
.service-list-row:last-child {
  padding-bottom: 0;
  margin-bottom: -35px;
}
.service-list-row:first-child:not(.not-connections) .service-list-item::before,
.service-list-row:last-child:not(.not-connections) .service-list-item::before {
  display: block;
  content: '';
  width: 2000px;
  height: 1px;
  position: absolute;
  top: 120px;
  right: calc(100% - 30px);
  background-color: #ff0048;
}
.service-list-row:first-child:not(.not-connections) .service-list-item::after,
.service-list-row:last-child:not(.not-connections) .service-list-item::after {
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #ff0048;
  position: absolute;
  top: 116px;
  right: calc(100% - 30px);
  border-radius: 50%;
}
.service-list-row__wide-image {
  z-index: 1;
}
.service-list-row--image-right .service-list-item {
  padding: 80px 120px 80px 80px;
}
.service-list-row--image-right .service-list-item__image {
  right: 0;
}
.service-list-row--image-right .service-list-row__number {
  left: -95px;
}
.service-list-row--image-left .service-list-item {
  margin-left: auto;
  padding: 80px 80px 80px 120px;
}
.service-list-row--image-left .service-list-item__image {
  left: 0;
}
.service-list-row--image-left .service-list-row__number {
  right: -95px;
}
.service-list-item {
  width: 100%;
  max-width: 540px;
  background-color: #fff;
  box-shadow: 4px 4px 13px 0 rgba(182, 173, 189, 0.47);
  position: relative;
  z-index: 1;
}
.service-list-item__title {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 30px;
}
.service-list-item__text {
  color: #4a4a4a;
  line-height: 1.5;
}
.service-list-item__text a:hover {
  text-decoration: underline;
}
.service-list-item__image {
  position: absolute;
  top: 0;
  z-index: 1;
}
.service-list-row__number {
  font-size: 337px;
  line-height: 1;
  color: #e1d9e3;
  font-weight: 800;
  opacity: 0.25;
  position: absolute;
  top: -120px;
  z-index: 0;
}
section.service-result {
  padding: 170px 0 120px;
  background-color: #ffecf1;
  position: relative;
}
section.service-result::before {
  display: block;
  content: '';
  width: 1px;
  height: 130px;
  background-color: #ff0048;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
section.service-result::after {
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #ff0048;
  border-radius: 50%;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.service-result-wrapper {
  max-width: 600px;
  margin: 0 auto;
}
.service-result__title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  margin-bottom: 30px;
}
.service-result__subtitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 60px;
}
.service-result__text {
  line-height: 1.5;
  color: #4a4a4a;
}
.service-result__text ul {
  padding-left: 30px;
}
.service-result__text ul li {
  list-style-type: disc;
  margin-bottom: 20px;
}
section.related-services {
  padding: 100px 30px 0px;
  background-color: #fff;
}
.related-services__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25;
  text-transform: uppercase;
  margin-bottom: 45px;
  text-align: center;
}
.related-services-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.related-services__item {
  padding: 40px 50px;
  box-shadow: 0 0 19px 0 rgba(130, 130, 130, 0.21);
  width: 100%;
  max-width: 300px;
  margin: 0 20px;
}
.related-services__image-wrapper {
  width: 140px;
  height: 140px;
  background-color: rgba(255, 0, 72, 0.1);
  border-radius: 50%;
  position: relative;
  margin-bottom: 40px;
}
.related-services__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.related-services__link span {
  font-size: 12px;
  font-weight: 700;
  color: #ff0048;
  text-transform: uppercase;
  margin-right: 10px;
}
.related-services__link svg {
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  transition: all 0.5s;
}
.related-services__name {
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.3;
  height: 66px;
  overflow: hidden;
}
.related-services__name a {
  color: #000;
}
@media only screen and (min-width: 992px) {
  .related-services__link:hover svg {
    transform: translateX(10px);
  }
}
@media only screen and (max-width: 1199px) {
  .service-tabs-wrapper {
    padding: 70px 30px;
  }
  .service-list-row {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 991px) {
  .service-tabs-wrapper {
    padding: 50px 0;
  }
  .service-tabs__item-title {
    margin-bottom: 15px;
  }
  .service-tabs__item-image-wrapper {
    flex: 0 0 250px;
    max-width: 250px;
    height: 250px;
  }
  .service-special_wrapper {
    padding: 0 15px;
  }
  .service-list-row:first-child::before {
    top: 240px;
    right: 280px;
    height: calc(100% - 240px + 70px);
  }
  .service-list-row:first-child::after {
    right: 275px;
  }
  .service-list-row:first-child .service-list-row__number {
    top: -100px;
  }
  .service-list-row--image-right .service-list-item__image {
    right: -40px;
  }
  .service-list-row--image-right .service-list-item {
    padding: 60px 100px 60px 60px;
  }
  .service-list-row--image-right .service-list-row__number {
    left: -25px;
  }
  .service-list-row--image-left .service-list-item {
    padding: 60px 40px 60px 120px;
  }
  .service-list-row--image-left .service-list-item__image {
    left: -40px;
  }
  .service-list-row--image-left .service-list-row__number {
    right: -25px;
  }
  .service-list-item__image {
    max-width: 370px;
  }
  .service-list-item {
    max-width: 500px;
  }
  .service-list-row__number {
    font-size: 300px;
  }
  section.service-result {
    padding-bottom: 80px;
  }
  section.related-services {
    padding: 60px 15px 60px;
  }
  .related-services__item:last-child {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .service-tabs__item {
    flex-direction: column;
  }
  .service-tabs-nav {
    border-bottom: none;
  }
  .service-tabs-nav__wrapper {
    flex-direction: column;
  }
  .service-tabs-nav__item {
    width: 100%;
    padding: 15px 25px;
    border-bottom: solid 1px rgba(155, 155, 155, 0.23);
  }
  .service-tabs__item-image-wrapper {
    flex: auto;
    max-width: 100%;
    width: 200px;
    height: 200px;
    margin: 0 auto 20px;
  }
  .service-tabs__item-text {
    flex: auto;
  }
  .service-tabs__item-title {
    text-align: center;
  }
  .service-tabs-nav__item--active::after {
    width: 5px;
    height: 100%;
  }
  .service-tabs-wrapper {
    padding: 40px 0;
  }
  section.service-special {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .service-list-row__number {
    display: none;
  }
  .service-list-row {
    flex-direction: column;
    padding: 0 0 50px;
  }
  .service-list-row::before,
  .service-list-row::after {
    display: none !important;
  }
  .service-list-item__image {
    position: static;
    order: 0;
  }
  .service-list-item {
    order: 1;
    padding: 30px 15px !important;
    box-shadow: none;
  }
  .service-list-item::before,
  .service-list-item::after {
    display: none !important;
  }
  .service-list-item__title {
    margin-bottom: 15px;
  }
  section.service-result {
    padding: 100px 0 50px;
  }
  section.service-result::before,
  section.service-result::after {
    display: none !important;
  }
  .service-result-wrapper {
    padding: 0 15px;
  }
  .service-result__subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .related-services-wrapper {
    flex-direction: column;
  }
  .related-services__item {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  section.service-list {
    padding: 0 0;
  }
}
.service-list-item__image,
.service-list-row__wide-image {
  opacity: 0;
}
.service-list-item__image.fade-in,
.service-list-row__wide-image.fade-in {
  opacity: 1;
  transition: opacity 1s ease;
}
.service-tabs__item-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: circle 20s linear infinite;
}
@keyframes circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.service-tabs__item-image {
  margin-top: 3%;
  animation: inner-circle 20s linear infinite;
}
@keyframes inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.related-services__item {
  transition: opacity .5s linear, transform 1s ease;
  opacity: 1;
}
.related-services__item.animate {
  opacity: 0;
  transform: translateY(40px);
}
@media only screen and (min-width: 768px) {
  .related-services__item:nth-child(2) {
    transition-delay: .6s;
  }
  .related-services__item:nth-child(3) {
    transition-delay: 1.2s;
  }
}
section.approach-list-general {
  background-color: #fff;
  padding: 65px 0 0;
}
.approach-list-general-row {
  margin-bottom: -50px;
  align-items: flex-end;
}
.approach-list-general-wrapper {
  padding-bottom: 120px;
}
.approach-list-general__text {
  line-height: 1.6;
  margin-bottom: 50px;
}
.approach-list-general__image {
  margin-left: auto;
}
.approach-list-general__link {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;
  transition: all 0.5s;
}
.approach-list-general__link span {
  margin-right: 10px;
}
.approach-list-general__link svg {
  display: inline-block;
  vertical-align: middle;
  transition: all 0.5s;
  margin-top: -2px;
}
section.approach-list {
  padding-top: 150px;
  padding-bottom: 100px;
  background-color: #f7f5f7;
}
.approach-list__title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 50px;
  text-align: center;
}
.approach-list__item {
  padding: 50px 40px 40px 140px;
  position: relative;
  transition: all 0.5s;
}
.approach-list__text {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.approach-list__image {
  position: absolute;
  top: 50px;
  left: 30px;
  width: 65px;
}
.approach-list__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
section.practice-list {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 90px;
  position: relative;
}
section.practice-list::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #fff;
  left: 0;
  width: 50%;
  z-index: 2;
}
.section-title--practice-list {
  font-size: 23px;
  text-align: left;
}
.section-title--practice-list::after {
  display: block;
  width: 50px;
  content: '';
  height: 1px;
  background-color: #ff5464;
  margin: 25px 0 30px;
}
.practice-list-row {
  min-height: 300px;
}
.practice-list__text {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.55;
  margin-bottom: 35px;
}
.practice-list-wrapper {
  z-index: 3;
}
.practice-list-slider {
  position: absolute;
  left: 50%;
  top: 60px;
  width: 1360px;
}
.practice-list-slider .owl-stage-outer {
  overflow: visible;
}
.practice-list__navigation {
  display: flex;
  align-items: center;
}
.practice-list__arrow {
  cursor: pointer;
}
.practice-list__arrow-path {
  transition: all 0.5s;
}
.practice-list__arrow--left {
  margin-right: 25px;
  transform: rotate(180deg);
}
.practice-list__item {
  width: 300px;
  height: 300px;
  margin: 20px;
  padding: 40px 20px;
  position: relative;
  transition: all 0.5s;
}
.practice-list__item-wrapper {
  height: 220px;
  overflow: hidden;
  position: relative;
}
.practice-list__image {
  width: auto !important;
  height: 80px;
  margin-bottom: 25px;
}
.practice-list__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  margin-bottom: 10px;
}
.practice-list__text--item {
  transition: all 0.3s;
}
.practice-list__show-more {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.5s;
}
.practice-list__show-more span {
  margin-right: 10px;
}
.practice-list__show-more svg {
  margin-top: -2px;
}
.practice-list__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 992px) {
  .approach-list-general__link:hover {
    color: #ff0048;
  }
  .approach-list-general__link:hover svg {
    transform: translateX(10px);
  }
  .approach-list__item:hover {
    box-shadow: 0 0 13px 0 rgba(182, 173, 189, 0.47);
  }
  .practice-list__item:hover {
    box-shadow: 0 0 19px 0 rgba(130, 130, 130, 0.21);
  }
  .practice-list__item:hover .practice-list__show-more {
    opacity: 1;
  }
  .practice-list__item:hover .practice-list__text--item {
    opacity: 0;
  }
  .practice-list__arrow:hover .practice-list__arrow-path {
    stroke: #ff0048;
  }
}
@media only screen and (max-width: 991px) {
  section.approach-list-general {
    padding: 60px 0;
  }
  .approach-list-general-wrapper {
    padding-bottom: 0;
  }
  .approach-list-general-row {
    margin-bottom: 0;
    align-items: center;
  }
  .approach-list-general__text {
    line-height: 1.5;
    margin-bottom: 30px;
  }
  section.approach-list {
    padding: 60px 0;
  }
  .approach-list__item {
    padding: 30px 10px 30px 70px;
  }
  .approach-list__image {
    top: 30px;
    left: 0;
    width: 50px;
  }
  .approach-list__title {
    margin-bottom: 30px;
  }
  section.practice-list {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  section.practice-list::after {
    display: none;
  }
  .practice-list-slider {
    position: relative;
    top: 0;
    left: 0;
  }
  .practice-list-row {
    min-height: 0;
  }
  .practice-list__text {
    max-width: 600px;
  }
}
@media only screen and (max-width: 767px) {
  section.approach-list-general {
    padding: 40px 0 50px;
  }
  .approach-list-general__image {
    margin-bottom: 20px;
  }
  section.approach-list {
    padding: 50px 0;
  }
  .approach-list__title {
    font-size: 20px;
  }
}
.page-wrap--marketing-approach section.principles {
  padding: 120px 0 20px;
}
.page-wrap--marketing-approach .principles-wrapper {
  margin-bottom: 0;
}
.page-wrap--marketing-approach .principles-navigation__text--mobile {
  display: none;
}
.page-wrap--marketing-approach .principles-list__item a {
  display: none;
}
.hiddentext {
  padding: 65px 0;
  background: #fff;
}
.hiddentext .container {
  max-width: 986px;
}
.hiddentext__title {
  position: relative;
  margin-bottom: 60px;
}
.hiddentext__title span {
  display: block;
  font-size: 16px;
  text-transform: lowercase;
  margin-top: 15px;
}
.hiddentext__title:before {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 54px;
  height: 2px;
  background: #ff0048;
}
.hiddentext__container {
  max-width: 630px;
  margin: 0 auto;
}
.hiddentext__text {
  position: relative;
  max-height: 190px;
  overflow: hidden;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  transition: 0.25s ease;
}
.hiddentext__text p + p {
  margin-top: 20px;
}
.hiddentext__text:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.21), #ffffff 100%);
  opacity: 1;
  visibility: visible;
  transition: 0.25s ease;
}
.hiddentext__text.opened {
  max-height: 9999px;
  transition: 0.25s ease;
}
.hiddentext__text.opened:after {
  opacity: 0;
  visibility: hidden;
  transition: 0.25s ease;
}
.hiddentext__arrow svg {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 25px;
  height: 15px;
}
.hiddentext__arrow_hide {
  display: none;
}
.opened .hiddentext__arrow_show,
.opened .hidden-list__arrow_show {
  display: none;
}
.opened .hiddentext__arrow_hide,
.opened .hidden-list__arrow_hide {
  display: block;
}
.services-list.services-list_results {
  padding-bottom: 5px;
  background: rgba(255, 0, 72, 0.1);
}
.services-list.services-list_results .services-list__title {
  margin-bottom: 40px;
}
.service-list_marketing {
  padding-bottom: 60px;
}
.service-list_marketing .services-list__image {
  top: 0;
}
.hidden-list {
  position: relative;
  max-height: 580px;
  overflow: hidden;
  transition: 0.5s linear;
}
.hidden-list:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.21) 40%, #ffffff 100%);
  opacity: 1;
  visibility: visible;
  transition: 0.25s ease;
}
.hidden-list.opened {
  max-height: 9999px;
  transition: 0.5s linear;
  overflow: visible;
}
.hidden-list.opened:after {
  opacity: 0;
  visibility: hidden;
  transition: 0.25s ease;
}
.hidden-list__arrow svg {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 25px;
  height: 15px;
}
.hidden-list__arrow_hide {
  display: none;
}
@media only screen and (max-width: 991px) {
  .page-wrap--marketing-approach section.principles {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 767px) {
  .page-wrap--marketing-approach .principles-navigation__item {
    margin-bottom: 50px;
    padding-left: 85px;
  }
  .page-wrap--marketing-approach .principles-navigation__text {
    font-weight: 400 !important;
  }
  .page-wrap--marketing-approach .principles-navigation__text--mobile {
    display: block;
    margin-bottom: 30px;
    font-weight: 400 !important;
    max-width: 100%;
  }
  .page-wrap--marketing-approach .principles-navigation__image {
    max-width: 55px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 575px) {
  .page-wrap--marketing-page .marketing-list {
    padding: 40px 0;
  }
}
section.unique-practice {
  padding-top: 80px;
  padding-bottom: 1px;
  background-color: #fff;
}
.unique-practice__intro {
  max-width: 600px;
  margin: 0 auto 90px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.2;
  color: #ff0048;
}
.unique-practice__intro::after {
  display: block;
  content: '';
  width: 52px;
  height: 1px;
  background-color: #ff0048;
  margin: 25px auto 0;
}
.unique-practice__list {
  max-width: 940px;
  margin: 0 auto;
}
.unique-practice__item {
  padding: 45px 0 35px;
  margin-bottom: 50px;
}
.unique-practice__item:nth-child(odd) {
  background-color: #f9f8fb;
}
.unique-practice__item:hover .unique-practice__image {
  transform: scale(1.2);
}
.unique-practice__image {
  transition: all 0.5s;
}
.unique-practice__text {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.25;
  padding-right: 80px;
}
.unique-practice__text p {
  margin-bottom: 20px;
}
.unique-practice__text a {
  color: #ff3043;
}
.unique-practice__hidden-text {
  display: none;
}
.unique-practice__wrapper {
  position: relative;
}
.unique-practice__title {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  padding-left: 170px;
  padding-right: 10px;
}
.unique-practice__image {
  position: absolute;
  top: 0;
  left: 40px;
}
.unique-practice__button {
  display: none;
  font-size: 12px;
  font-weight: 700;
  color: #ff5464;
  letter-spacing: 2px;
  cursor: pointer;
  padding-right: 15px;
}
.unique-practice__button::after {
  display: inline-block;
  content: '';
  width: 7px;
  height: 7px;
  border-top: 2px solid #ff5464;
  border-right: 2px solid #ff5464;
  transform: rotate(135deg);
  margin-left: 12px;
  margin-bottom: 1px;
  transition: all 0.5s;
}
.unique-practice__button--more {
  display: inline-block;
}
.unique-practice__button--less::after {
  transform: rotate(-45deg);
  margin-bottom: -3px;
}
.unique-practice__text--open .unique-practice__button--less {
  display: inline-block;
}
.unique-practice__text--open .unique-practice__button--more {
  display: none;
}
@media only screen and (min-width: 992px) {
  .unique-practice__text a:hover {
    text-decoration: underline;
  }
  .unique-practice__button--more:hover::after {
    transform: translateX(5px) rotate(135deg);
  }
  .unique-practice__button--less:hover::after {
    transform: translateX(5px) rotate(-45deg);
  }
}
@media only screen and (max-width: 991px) {
  .unique-practice__text {
    padding-right: 20px;
  }
  .unique-practice__title {
    padding-left: 80px;
  }
  .unique-practice__image {
    left: -20px;
  }
}
@media only screen and (max-width: 767px) {
  section.unique-practice {
    padding-top: 40px;
    padding-bottom: 1px;
  }
  .unique-practice__wrapper {
    margin-bottom: 30px;
  }
  .unique-practice__intro {
    font-size: 16px;
    margin-bottom: 40px;
  }
  .unique-practice__item {
    margin-bottom: 30px;
    padding: 25px 15px;
  }
  .unique-practice__title {
    padding-right: 70px;
    font-size: 16px;
    padding-left: 0;
  }
  .unique-practice__text {
    padding-right: 0;
  }
  .unique-practice__image {
    max-width: 60px;
    max-height: 60px;
    left: auto;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.page-wrap--company section.reviews {
  background-color: #fff;
}
section.partners {
  padding-top: 100px;
  padding-bottom: 70px;
  background-color: #fff;
}
section.partners .company__link--video {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -31px;
  margin-top: -26px;
}
.partners-row {
  margin-bottom: 50px;
}
.section-title--partners {
  padding-top: 30px;
  padding-bottom: 25px;
  text-align: left;
}
.partners-text {
  color: #544b5c;
  line-height: 1.5;
  max-width: 425px;
}
.partners__image {
  margin-left: auto;
}
.company-numbers__wrapper--partners {
  margin: 0 auto 65px;
  box-shadow: none;
}
.partners-button-wrapper {
  text-align: center;
}
.partners-button {
  color: #544b5c;
  font-size: 12px;
  font-weight: 700;
  padding: 18px 20px 15px;
  min-width: 230px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 100px;
  border: solid 1px #000000;
  display: inline-block;
  transition: all 0.5s;
}
section.principles {
  padding-top: 70px;
  background-color: #fff;
}
.section-title--principles {
  margin-bottom: 50px;
}
.principles-wrapper {
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.principles-navigation {
  flex: 0 0 50%;
  max-width: 50%;
}
.principles-navigation__item {
  margin-bottom: 60px;
  padding-left: 100px;
  position: relative;
  cursor: pointer;
}
.principles-navigation__item:last-child {
  margin-bottom: 0;
}
.principles-navigation__item--active {
  z-index: 2;
  /*&::after {
    display: block;
    content: '';
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: @red_main;
    position: absolute;
    top: 3px;
    right: -30px;
  }*/
}
.principles-navigation__item--active::before {
  display: block;
  content: '';
  width: 75px;
  height: 1px;
  background-color: #ff0048;
  position: absolute;
  top: 8px;
  right: -30px;
}
.principles-navigation__item--active .principles-navigation__text {
  font-weight: 700;
}
.principles-navigation__item--active .principles-navigation__image--off {
  opacity: 0;
}
.principles-navigation__item--active .principles-navigation__image--on {
  opacity: 1;
}
.principles-navigation__text {
  font-size: 14px;
  line-height: 1.43;
  max-width: 300px;
  transition: all 0.5s;
}
.principles-navigation__image {
  position: absolute;
  top: 0;
  left: 0;
  /*opacity: 0.3;*/
  transition: all 0.5s;
  max-width: 60px;
}
.principles-navigation__image--on {
  opacity: 0;
}
.principles-navigation__image--off {
  opacity: 1;
}
.principles-list {
  flex: 0 0 50%;
  max-width: 50%;
  height: 480px;
  box-shadow: 4px 4px 13px 0 rgba(182, 173, 189, 0.47);
  position: relative;
}
.principles-list__item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 70px 50px 100px;
  background-color: #fff;
  z-index: 0;
  transition: all 0.5s;
}
.principles-list__item p {
  color: #544b5c;
  line-height: 1.5;
  margin-bottom: 20px;
}
.principles-list__item p:last-child {
  margin-bottom: 0;
}
.principles-list__item--active {
  z-index: 1;
}
.principles-list__image {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  max-width: 180px;
}
.principles-list__link {
  position: absolute;
  left: 50px;
  bottom: 60px;
}
.principles-list__link span {
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  margin-right: 10px;
  color: #544b5c;
  transition: all 0.5s;
}
.principles-list__link svg {
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  transition: all 0.5s;
}
section.principles-image {
  background: #fff;
  padding-top: 150px;
}
.principles__image {
  margin: 0 auto -60px;
}
section.structure {
  padding-top: 160px;
  padding-bottom: 120px;
  background-color: #f5f3f6;
}
.structure__title {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.structure__text {
  color: #544b5c;
  line-height: 1.5;
  margin-bottom: 40px;
}
.structure__link span {
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  margin-right: 10px;
  transition: all 0.5s;
  color: #000000;
}
.structure__link svg {
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  transition: all 0.5s;
}
.structure-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
}
.structure-block {
  flex: 0 0 230px;
  max-width: 230px;
}
.structure-block--center {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.structure-block--center::before {
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #ff0048;
  position: absolute;
  left: 0;
  top: 35px;
  z-index: 0;
}
.structure-block__image {
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  background-color: #f5f3f6;
}
.structure-block__title {
  color: #544b5c;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.25;
  text-align: center;
}
.structure-block__item {
  background-color: #fff;
  padding: 20px;
  color: #544b5c;
  font-size: 12px;
  line-height: 1.25;
  box-shadow: 4px 4px 13px 0 rgba(182, 173, 189, 0.47);
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}
.structure-block__item:last-child {
  margin-bottom: 0;
}
.structure-block__item::before {
  display: block;
  content: '';
  height: calc(100% + 20px);
  width: 1px;
  background-color: #ff0048;
  position: absolute;
  top: 0;
}
.structure-block__item::after {
  display: block;
  content: '';
  width: 30px;
  height: 1px;
  background-color: #ff0048;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.structure-block__item:first-child::before {
  height: calc(50% + 20px);
  top: 50%;
}
.structure-block__item:last-child::before {
  height: 50%;
}
.structure-block--right {
  padding-left: 30px;
}
.structure-block--right .structure-block__item::after {
  right: 100%;
}
.structure-block--right .structure-block__item::before {
  left: -30px;
}
.structure-block--left {
  padding-right: 30px;
}
.structure-block--left .structure-block__item::after {
  left: 100%;
}
.structure-block--left .structure-block__item::before {
  right: -30px;
}
.structure-wrapper-mobile {
  display: none;
}
section.vacancies-list {
  background-color: #f4f4f4;
  padding-top: 110px;
  padding-bottom: 110px;
  position: relative;
}
.section-title--vacancies-list {
  font-size: 23px;
  text-align: left;
  padding-top: 40px;
}
.section-title--vacancies-list::after {
  display: block;
  width: 50px;
  content: '';
  height: 1px;
  background-color: #ff5464;
  margin: 25px 0 30px;
}
.vacancies-list-row {
  min-height: 300px;
}
.vacancies-list__text {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.55;
  max-width: 360px;
  margin-bottom: 60px;
}
.vacancies-list__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.vacancies-list__item {
  width: 300px;
  min-height: 370px;
  padding: 50px 30px 50px 60px;
  position: relative;
  transition: all 0.5s;
}
.vacancies-list__item-wrapper {
  position: relative;
}
.vacancies-list__image {
  width: auto !important;
  position: absolute;
  top: 0;
  left: 0;
}
.vacancies-list__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  margin-bottom: 10px;
  padding-top: 140px;
}
.vacancies-list__text--item {
  transition: all 0.3s;
  margin-bottom: 20px;
}
.vacancies-list__show-more {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.5s;
}
.vacancies-list__show-more span {
  margin-right: 10px;
}
.vacancies-list__show-more svg {
  margin-top: -2px;
}
.vacancies-list__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.blog-list__read-more-wrapper--left {
  text-align: left;
}
.vacancies-image {
  display: none;
  margin-left: auto;
}
@media only screen and (min-width: 992px) {
  .partners-button:hover {
    background-color: #000000;
    color: #fff;
  }
  .principles-list__link:hover span {
    color: #ff0048;
  }
  .principles-list__link:hover svg {
    transform: translateX(10px);
  }
  .structure__link:hover span {
    color: #ff0048;
  }
  .structure__link:hover svg {
    transform: translateX(10px);
  }
  .vacancies-list__item:hover {
    box-shadow: 0 0 19px 0 rgba(130, 130, 130, 0.21);
    background-color: #fff;
  }
  .vacancies-list__item:hover .vacancies-list__show-more {
    opacity: 1;
  }
}
@media only screen and (max-width: 991px) {
  section.partners {
    padding: 60px 0;
  }
  .section-title--partners {
    padding-top: 0;
  }
  section.principles {
    padding-top: 50px;
  }
  .principles-wrapper {
    margin-bottom: 100px;
  }
  .principles-navigation__item {
    margin-bottom: 35px;
    padding-left: 60px;
    padding-right: 20px;
  }
  .principles-navigation__item--active::before {
    right: -10px;
    top: auto;
    bottom: -10px;
  }
  .principles-navigation__image {
    max-width: 45px;
  }
  .principles-list {
    height: 450px;
  }
  .principles-list__link {
    left: 30px;
    bottom: 50px;
  }
  .principles-list__item {
    padding: 30px;
  }
  .principles-list__image {
    max-width: 100px;
  }
  .principles__image {
    margin-bottom: 0;
  }
  section.structure {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .structure-wrapper {
    padding-left: 0;
  }
  .structure__link {
    display: inline-block;
    margin-bottom: 30px;
  }
  section.vacancies-list {
    padding: 60px 0;
  }
  .vacancies-list__item {
    box-shadow: 0 0 19px 0 rgba(130, 130, 130, 0.21);
    background-color: #fff;
  }
  .vacancies-list__item:last-of-type {
    display: none;
  }
  .vacancies-list__wrapper {
    justify-content: center;
  }
  .vacancies-list__show-more {
    opacity: 1;
  }
}
@media only screen and (max-width: 767px) {
  section.partners {
    padding: 30px 0;
  }
  .partners__image {
    margin-bottom: 20px;
  }
  .partners-row {
    margin-bottom: 20px;
  }
  .company-numbers__wrapper--partners {
    margin-bottom: 40px;
  }
  section.principles-image {
    padding-top: 50px;
  }
  .principles-list {
    display: none;
  }
  .principles-navigation {
    flex: auto;
    max-width: 100%;
  }
  .principles-navigation__item {
    padding-right: 0;
  }
  .principles-navigation__image--on {
    opacity: 1;
  }
  .principles-navigation__image--off {
    opacity: 0;
  }
  .principles-navigation__item--active .principles-navigation__text {
    font-weight: 400;
  }
  .principles-navigation__item--active::before {
    display: none;
  }
  .principles-wrapper {
    margin-bottom: 0px;
  }
  section.structure {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .structure-wrapper {
    display: none;
  }
  .structure__link {
    display: none;
  }
  section.vacancies-list {
    padding: 40px 0;
  }
  .section-title--vacancies-list {
    padding-top: 0;
  }
  .section-title--vacancies-list::after {
    margin: 15px 0 30px;
  }
  .vacancies-list__text {
    margin-bottom: 30px;
  }
  .vacancies-list__wrapper {
    padding-top: 30px;
    justify-content: flex-start;
  }
  .structure-wrapper-mobile {
    display: block;
    text-align: center;
  }
  .structure-wrapper-mobile .structure-block__title {
    margin-bottom: 15px;
    font-size: 14px;
  }
  .structure-wrapper-mobile .structure-block__image {
    margin: 0 auto 30px;
  }
  .structure-wrapper-mobile .structure-block-mobile {
    background-color: #fff;
    box-shadow: 4px 4px 13px 0 rgba(182, 173, 189, 0.47);
    border-radius: 4px;
    padding: 35px 50px 45px;
    max-width: 240px;
    margin: 0 auto 30px;
    position: relative;
  }
  .structure-wrapper-mobile .structure-block-mobile::before {
    display: block;
    content: '';
    width: 1px;
    height: 40px;
    background-color: #ff0048;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .structure-wrapper-mobile .structure-block-mobile__item {
    font-size: 12px;
    color: #544b5c;
    line-height: 1.3;
    margin-bottom: 25px;
  }
  .structure-wrapper-mobile .structure-block-mobile__item:last-child {
    margin-bottom: 0;
  }
  .structure-wrapper-mobile .structure__link {
    display: inline-block;
  }
}
.structure-wrapper .structure-block--center:before {
  opacity: 1;
  transition: opacity 0.6s 3.1s ease-in-out;
}
.structure-wrapper .structure-block--center svg {
  opacity: 1;
  transform: scale(1);
  transition: opacity .5s linear, transform 0.5s ease;
}
.structure-wrapper .structure-block--center svg path:not(:first-child) {
  transition: transform 0.5s 0.3s ease;
  transform: translateY(0px);
}
.structure-wrapper .structure-block--center .structure-block__title {
  opacity: 1;
  transition: opacity 0.5s 0.8s linear;
}
.structure-wrapper .structure-block__item {
  transition: opacity .5s linear, transform 0.5s ease;
  opacity: 1;
  transform: translateY(0px);
}
.structure-wrapper .structure-block__item:before,
.structure-wrapper .structure-block__item:after {
  opacity: 1;
  transition: opacity 0.6s 3.1s ease-in-out;
}
.structure-wrapper .structure-block--right .structure-block__item:nth-child(1) {
  transition-delay: 1.2s;
}
.structure-wrapper .structure-block--right .structure-block__item:nth-child(2) {
  transition-delay: 1.6s;
}
.structure-wrapper .structure-block--right .structure-block__item:nth-child(3) {
  transition-delay: 2s;
}
.structure-wrapper .structure-block--right .structure-block__item:nth-child(4) {
  transition-delay: 2.4s;
}
.structure-wrapper .structure-block--right .structure-block__item:nth-child(5) {
  transition-delay: 2.8s;
}
.structure-wrapper .structure-block--left .structure-block__item:nth-child(1) {
  transition-delay: 1.4s;
}
.structure-wrapper .structure-block--left .structure-block__item:nth-child(2) {
  transition-delay: 1.8s;
}
.structure-wrapper .structure-block--left .structure-block__item:nth-child(3) {
  transition-delay: 2.2s;
}
.structure-wrapper .structure-block--left .structure-block__item:nth-child(4) {
  transition-delay: 2.6s;
}
.structure-wrapper.animate .structure-block--center:before {
  opacity: 0;
}
.structure-wrapper.animate .structure-block--center svg {
  opacity: 0;
  transform: scale(0.2);
}
.structure-wrapper.animate .structure-block--center svg path:not(:first-child) {
  transform: translateY(60px);
}
.structure-wrapper.animate .structure-block__title {
  opacity: 0;
}
.structure-wrapper.animate .structure-block__item {
  opacity: 0;
  transform: translateY(40px);
}
.structure-wrapper.animate .structure-block__item:before,
.structure-wrapper.animate .structure-block__item:after {
  opacity: 0;
}
.page-wrap--about-company-page .page-banner__title .normal {
  text-transform: none;
  margin-bottom: 10px;
}
.page-wrap--about-company-page section.structure {
  padding-bottom: 140px;
}
.page-wrap--about-company-page section.structure .structure__link {
  display: none;
}
section.partners--about-company {
  padding-bottom: 0;
}
.company-numbers__wrapper--about-company {
  margin-bottom: 30px;
}
/*
section.structure--about-company {
  padding: 160px 0;
}
*/
section.about-content {
  padding-bottom: 80px;
  background-color: #fff;
}
.about-content__wrapper {
  padding-top: 70px;
  max-width: 400px;
}
.about-content__title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 30px;
}
.about-content__text {
  color: #544b5c;
  line-height: 1.5;
}
.about-content__image {
  margin-top: -50px;
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .page-wrap--about-company-page section.structure {
    padding-bottom: 120px;
  }
  section.about-content {
    padding: 60px 0;
  }
  section.about-content .row {
    align-items: center;
  }
  .about-content__image {
    margin-top: 0;
  }
  .about-content__wrapper {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .page-wrap--about-company-page section.structure {
    padding-bottom: 20px;
  }
  section.about-content {
    padding: 30px 0 40px;
  }
  .about-content__image {
    margin-bottom: 15px;
  }
  .about-content__title {
    margin-bottom: 15px;
  }
}
section.contacts-main {
  padding-top: 60px;
  padding-bottom: 80px;
}
.contacts-main__title {
  font-size: 44px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.contacts-main__subtitle {
  max-width: 425px;
  font-size: 24px;
  color: #ff0048;
  line-height: 1.33;
}
section.contacts-list {
  padding-bottom: 150px;
}
.contacts-list__title {
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 25px;
}
.contacts-list__title span {
  vertical-align: middle;
}
.contacts-list__title svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}
.contacts-list__text {
  font-size: 17px;
  color: #544b5c;
  transition: all 0.5s;
  margin-bottom: 10px;
}
.contacts-list__link {
  display: inline-block;
  font-size: 15px;
  line-height: 1.6;
  color: #ff0048;
  overflow: hidden;
  position: relative;
  transition: all 0.5s;
}
.contacts-list__link::before {
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #ff0048;
  position: absolute;
  left: 0;
  bottom: 0;
}
.contacts-list__link::after {
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #000000;
  transition: all 0.5s;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
}
@media only screen and (min-width: 992px) {
  .contacts-list__link:hover {
    color: #000000;
  }
  .contacts-list__link:hover::after {
    transform: translateX(0);
  }
  a.contacts-list__text:hover {
    color: #ff0048;
  }
}
@media only screen and (max-width: 991px) {
  section.contacts-main {
    padding-top: 30px;
  }
  section.contacts-list {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767px) {
  section.contacts-main {
    padding-bottom: 50px;
  }
  .contacts-main__title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .contacts-main__subtitle {
    font-size: 18px;
  }
  .contacts-list__title {
    margin-bottom: 10px;
  }
  .contacts-list__item {
    margin-bottom: 30px;
  }
  section.contacts-list {
    padding-bottom: 50px;
  }
}
section.vacancies-main {
  padding-top: 70px;
  padding-bottom: 80px;
}
.vacancies-main__title {
  font-size: 44px;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.vacancies-main_subtitle {
  font-size: 36px;
  line-height: 1;
  color: #ff0048;
  margin-bottom: 60px;
}
.vacancies-main__text p {
  color: #544b5c;
  margin-bottom: 20px;
  line-height: 1.5;
}
section.vacancies {
  padding-bottom: 25px;
}
.vacancies__item {
  padding: 60px 0 50px;
  border-top: 1px solid #979797;
}
.vacancies__title {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.25;
}
.vacancies__text {
  color: #544b5c;
  line-height: 1.5;
  margin-bottom: 40px;
}
.vacancies__link {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #544b5c;
  padding: 18px 20px 15px;
  text-align: center;
  min-width: 230px;
  border-radius: 100px;
  background-color: rgba(84, 75, 92, 0.15);
  transition: all 0.5s;
}
section.vacancy-apply {
  background-color: #f5f2f6;
  padding: 80px 0;
}
.vacancy-apply__title {
  font-size: 23px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 60px;
}
.vacancy-form__row {
  margin-bottom: 40px;
}
.vacancy-form__field {
  margin-bottom: 20px;
  position: relative;
}
.vacancy-form__label {
  color: #544b5c;
  line-height: 24px;
  display: flex;
  margin-bottom: 4px;
}
.vacancy-form__label span {
  font-size: 30px;
  color: #f9593a;
  line-height: 24px;
}
.vacancy-form__input {
  display: block;
  width: 100%;
  height: 52px;
  background-color: rgba(215, 204, 219, 0.46);
  padding: 0 50px 0 15px;
  font-size: 14px;
  color: #000000;
  border: none;
  line-height: 52px;
  text-indent: 30px;
}
.vacancy-form__field_resume input {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}
.vacancy-form__field_resume .vacancy-form__input {
  position: relative;
  cursor: pointer;
}
.vacancy-form__field_resume .vacancy-form__input svg {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.vacancy-form__input--textarea {
  height: 150px;
  padding: 10px 15px;
}
.vacancy-form__required-message {
  font-size: 13px;
  color: #9fa3a7;
  line-height: 13px;
  display: flex;
}
.vacancy-form__required-message span {
  font-size: 30px;
  color: #f9593a;
  line-height: 13px;
  margin-right: 5px;
}
.vacancy-form__button-wrapper {
  text-align: center;
}
.vacancy-form__button {
  padding: 13px;
  min-width: 200px;
}
@media only screen and (min-width: 992px) {
  .vacancies__link:hover {
    color: #fff;
    background-color: #000000;
  }
}
@media only screen and (max-width: 991px) {
  section.vacancies-main {
    padding-top: 20px;
  }
  .vacancies-main__title {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .vacancies-main__title {
    font-size: 28px;
  }
  .vacancies-main_subtitle {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .vacancies-main__image {
    margin-bottom: 15px;
  }
  section.vacancies-main {
    padding-bottom: 30px;
  }
  .vacancies__item {
    padding: 40px 0;
  }
  .vacancies__title {
    margin-bottom: 15px;
  }
  section.vacancy-apply {
    padding: 50px 0;
  }
  .vacancy-apply__title {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .vacancy-form__input {
    height: 42px;
  }
}
section.vacancy-main {
  padding-top: 30px;
}
.vacancy-main__wrapper {
  max-width: 810px;
  padding: 0 15px;
  margin: 0 auto;
}
.vacancy-main__category {
  font-size: 17px;
  line-height: 1;
  color: #ff0048;
  margin-bottom: 10px;
}
.vacancy-main__title {
  font-size: 29px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 30px;
}
.vacancy-main__description {
  margin-bottom: 30px;
  color: #544b5c;
}
.vacancy-main__description p {
  margin-bottom: 10px;
}
.vacancy-main__subtitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: #544b5c;
  margin-bottom: 20px;
}
.vacancy-main__text {
  color: #544b5c;
  margin-bottom: 50px;
}
.vacancy-main__text p {
  margin-bottom: 10px;
}
.vacancy-main__text ul {
  padding-left: 20px;
  margin-bottom: 25px;
}
.vacancy-main__image {
  margin: 0 auto -60px;
}
section.vacancy-apply--vacancy-page {
  padding-top: 100px;
}
@media only screen and (max-width: 767px) {
  .vacancy-main__title {
    font-size: 22px;
    line-height: 1.25;
  }
}
.faq-page {
  background: #E5E5E5;
}
.faq__title {
  text-align: center;
  font-weight: 800;
  font-size: 44px;
  line-height: normal;
  text-transform: uppercase;
  color: #000;
}
@media screen and (max-width: 992px) {
  .faq__title {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  .faq__title {
    font-size: 30px;
  }
}
@media screen and (max-width: 575px) {
  .faq__title {
    font-size: 24px;
  }
}
.faq__subtitle {
  margin-bottom: 40px;
  font-weight: normal;
  font-size: 36px;
  line-height: normal;
  color: #FF0048;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .faq__subtitle {
    margin-bottom: 30px;
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  .faq__subtitle {
    font-size: 22px;
  }
}
@media screen and (max-width: 575px) {
  .faq__subtitle {
    margin-bottom: 20px;
    font-size: 18px;
  }
}
.faq-block__title {
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: normal;
  color: #000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
}
.faq-block__title.is-active {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 992px) {
  .faq-block__title {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .faq-block__title {
    font-size: 18px;
  }
}
@media screen and (max-width: 575px) {
  .faq-block__title {
    font-size: 16px;
  }
}
.faq-block__list {
  margin: 20px auto 60px;
  padding: 0 40px;
  background: #ffffff;
}
@media screen and (max-width: 992px) {
  .faq-block__list {
    margin: 15px auto 50px;
    padding: 0 30px;
  }
}
@media screen and (max-width: 575px) {
  .faq-block__list {
    margin: 15px auto 30px;
    padding: 0 15px;
  }
}
.faq-block__item {
  padding: 0px 200px 0px 100px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.3);
  transition: all .9s ease;
}
.faq-block__item:last-child {
  border-bottom: 0;
}
.faq-block__item.is-active {
  padding-bottom: 30px;
  transition: all 0.5s ease-in-out;
}
.faq-block__item.is-active .faq-block__question:after {
  transform: rotate(180deg);
  transition: 0.5s ease;
}
.faq-block__item.is-active .faq-block__answer {
  max-height: 9999px;
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 992px) {
  .faq-block__item {
    padding: 0px 80px 0px 60px;
  }
}
@media screen and (max-width: 767px) {
  .faq-block__item {
    padding: 0 50px 0 40px;
  }
}
@media screen and (max-width: 575px) {
  .faq-block__item {
    padding: 0 20px 0 30px;
  }
}
.faq-block__question {
  position: relative;
  padding: 20px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  color: #000000;
  cursor: pointer;
}
.faq-block__question:before,
.faq-block__question:after {
  content: '';
  position: absolute;
  top: 27.5px;
  left: -46px;
  width: 16px;
  height: 3px;
  background: #ff0048;
}
@media screen and (max-width: 992px) {
  .faq-block__question:before,
  .faq-block__question:after {
    left: -35px;
  }
}
@media screen and (max-width: 767px) {
  .faq-block__question:before,
  .faq-block__question:after {
    top: 21.5px;
    left: -30px;
    width: 13px;
  }
}
@media screen and (max-width: 575px) {
  .faq-block__question:before,
  .faq-block__question:after {
    top: 18.5px;
    left: -22px;
  }
}
.faq-block__question:after {
  transform: rotate(90deg);
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .faq-block__question {
    padding: 15px 0;
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .faq-block__question {
    padding: 12px 0;
  }
}
.faq-block__answer {
  position: relative;
  padding-left: 30px;
  max-height: 0;
  overflow: hidden;
  transition: all .9s ease;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #544B5C;
}
.faq-block__answer ul,
.faq-block__answer ol {
  padding-left: 20px;
}
.faq-block__answer ul li,
.faq-block__answer ol li {
  margin-bottom: 0.25em;
}
.faq-block__answer > * {
  margin-bottom: 0.5em;
}
.faq-block__answer:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  bottom: 14px;
  display: block;
  width: 1px;
  height: 100%;
  background: #ff0048;
}
@media screen and (max-width: 992px) {
  .faq-block__answer {
    padding-left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .faq-block__answer {
    font-size: 14px;
  }
}
section.blog-categories {
  width: 100%;
  padding: 0 15px;
  margin: 15px auto 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-categories__link {
  font-size: 14px;
  color: #4a4a4a;
  text-transform: uppercase;
  line-height: 1.75;
  transition: all 0.5s;
  padding: 0 10px;
}
.blog-categories__link--active {
  color: #ff0048;
}
section.blog-slider {
  position: relative;
}
section.blog-slider + .blog-list {
  padding-bottom: 0;
}
.blog-slider__item {
  width: 100%;
  height: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-slider__text {
  max-width: 650px;
  text-align: center;
  padding-top: 80px;
}
.blog-slider__title {
  font-size: 34px;
  font-weight: 800;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 50px;
  padding: 0 15px;
}
.blog-slider__dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  text-align: center;
}
.blog-slider__dot {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
}
.blog-slider__dot.active {
  background-color: #ff0048;
}
.blog-categories__button-wrapper {
  padding: 10px 15px 20px;
  display: none;
}
.blog-categories__button {
  border: 1px solid #d7ccdb;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
}
.blog-categories__arrow-wrapper {
  display: none;
}
@media only screen and (min-width: 992px) {
  .blog-categories__link:hover {
    color: #ff0048;
  }
  .blog-slider__dot:hover {
    background-color: #ff0048;
  }
}
@media only screen and (max-width: 991px) {
  .blog-categories__link {
    font-size: 13px;
    line-height: 1.2;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .blog-slider__title {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .blog-slider__item {
    height: 350px;
  }
  .blog-slider__dots {
    bottom: 10px;
  }
  .blog-slider__link {
    padding: 11px;
    min-width: 170px;
  }
  .blog-slider__text {
    padding-top: 20px;
  }
  section.blog-categories {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    flex-direction: column;
    z-index: 2;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: auto;
    padding: 20px 15px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
  }
  section.blog-categories.active {
    visibility: visible;
    opacity: 1;
  }
  .blog-categories__link {
    font-size: 14px;
    padding: 15px;
  }
  .blog-categories__arrow-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .blog-categories__button-wrapper {
    display: block;
  }
}
.post-header {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 60px;
}
.post-header .category {
  background-color: #ff5464;
  padding: 5px 10px;
  font-family: 'ProximaNova', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 15px;
}
.post-header .category a {
  color: #ffffff;
}
.post-header_title {
  font-family: 'ProximaNova-Extrabld', sans-serif;
  font-size: 38px;
  line-height: 1.25;
  color: #ffffff;
  margin: 0 0 20px 0;
  max-width: 610px;
}
.post-header .date {
  font-family: 'ProximaNova', sans-serif;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
}
.post-content_wrapper {
  background-color: #ffffff;
  margin-top: -110px;
  padding-top: 80px;
  position: relative;
}
.post-content_content {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  color: #4a4a4a;
  font-size: 18px;
  line-height: 1.33;
}
.post-content_content::after {
  display: block;
  content: '';
  width: 140px;
  height: 1.5px;
  background-color: #979797;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}
.post-content_content p {
  margin-bottom: 20px;
}
.post-content_content ul {
  margin-bottom: 20px;
  padding-left: 40px;
}
.post-content_content ul li {
  font-size: 18px;
  line-height: 1.33;
  color: #4a4a4a;
  margin-bottom: 10px;
}
.post-content_content blockquote {
  padding: 0;
  margin: 60px 0;
  border: none;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
  color: #ff5464;
  font-family: 'ProximaNova', sans-serif;
  font-weight: 300;
  font-style: italic;
}
.post-content_content h2 {
  margin-top: 70px;
  margin-bottom: 25px;
  font-size: 24px;
  font-family: 'ProximaNova', sans-serif;
  color: #000000;
}
.post-content_content h2 a {
  color: #ff3548;
  text-decoration: underline;
}
.post-content_content .socials {
  bottom: 195px;
  top: auto;
}
.post-content_content img,
.post-content_content video {
  max-width: 100%;
  margin: 50px auto 20px auto;
}
.post-content .post-authors {
  text-align: center;
  padding-top: 50px;
}
.post-content .post-authors_item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
  min-width: 150px;
}
.author img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
  max-width: 82px;
}
.author_name {
  font-family: 'ProximaNova', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: rgba(74, 74, 74, 0.65);
  line-height: 1;
  margin-bottom: 15px;
}
.author_position {
  font-family: 'ProximaNova', sans-serif;
  font-size: 14px;
  color: rgba(74, 74, 74, 0.65);
  line-height: 1;
}
article .content_wrapper .socials {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: right;
  position: absolute;
  top: 160px;
  right: 0;
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}
.socials.fixed {
  top: 50%;
  -webkit-transform: translatey(-50%);
  -moz-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  -o-transform: translatey(-50%);
  transform: translatey(-50%);
  position: fixed;
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}
.socials.bottom {
  bottom: 195px;
  top: auto;
}
.socials a {
  margin-bottom: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.socials a:last-child {
  margin-bottom: 0;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.post-video-header {
  position: relative;
  display: flex;
  max-height: 465px;
}
.post-video-header img {
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
}
.post-video-header .pulse-button {
  right: 50%;
  transform: translate(50%, -50%);
}
.post-video-content {
  padding-top: 30px;
}
.post-video__header {
  margin-bottom: 35px;
  padding: 30px 20px;
  background: #ff0048;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}
.post-video__date,
.post-video__tags,
.post-video__share {
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  text-align: left;
  color: #363636;
}
.post-video__date .name,
.post-video__tags .name,
.post-video__share .name {
  font-weight: bold;
  white-space: nowrap;
}
.post-video__tags a {
  color: #5b5b5b;
  transition: 0.25s ease-in-out;
}
.post-video__tags a:hover {
  color: #ff3448;
  transition: 0.25s ease-in-out;
}
.post-video__info {
  width: 30%;
  padding-left: 15px;
  padding-right: 38px;
}
.post-video__main {
  width: 70%;
  padding-right: 220px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #5b5b5b;
}
.post-video__main p {
  margin: 25px 0;
}
.post-video__share {
  margin-top: 30px;
}
.post-video__share_post .name {
  margin-right: 20px;
}
.post-video__link {
  display: inline-flex;
  width: 45px;
  height: 45px;
  margin-right: 10px;
}
.post-video__link svg {
  width: 100%;
  height: 100%;
}
.post-video__link svg .st0 {
  transition: 0.25s ease;
}
.post-video__link_fb:hover svg .st0 {
  fill: #3b5998;
  transition: 0.15s ease;
}
.post-video__link_tw:hover svg .st0 {
  fill: #1DA1F2;
  transition: 0.15s ease;
}
.timecode {
  margin-top: 15px;
}
.timecode__item {
  padding: 20px 25px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  text-align: left;
  color: #5b5b5b;
  box-shadow: 0px 3px 27px 0 rgba(141, 141, 141, 0.16);
}
.timecode__item span {
  font-weight: 600;
}
.custom-results {
  position: relative;
  margin-top: -130px;
  padding: 70px 90px 0px;
  border: solid 2px #e1e1e1;
  background: #fff;
}
.custom-results .section-title {
  margin-bottom: 70px;
  font-size: 26px;
  font-weight: bold;
  line-height: 0.92;
  text-align: center;
  color: #000000;
}
.custom-results__item {
  max-width: 290px;
  margin-bottom: 70px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.custom-results__num {
  position: relative;
  margin-bottom: 50px;
  display: block;
  font-size: 48px;
  font-weight: 600;
  color: #ff003e;
}
.custom-results__num:before {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 35px;
  height: 1px;
  background-color: #ff003e;
}
.image-holder-custom {
  margin-top: 78px;
  padding-bottom: 0;
}
.image-holder-custom img {
  position: relative;
  top: -128px;
  left: 50%;
  transform: translateX(-40%);
}
.image-holder-custom-two {
  margin-top: 111px;
  max-height: 655px;
}
.image-holder-custom-two img {
  position: relative;
  top: -111px;
}
.image-custom-three {
  position: relative;
  left: 50%;
  transform: translateX(-40%);
}
@media only screen and (max-width: 991px) {
  .custom-results {
    padding: 50px 50px 0px;
  }
  .custom-results__item {
    margin-bottom: 50px;
  }
  .post-video__info {
    width: 40%;
    padding-right: 25px;
  }
  .post-video__main {
    width: 60%;
    padding-right: 75px;
    font-size: 16px;
  }
  .post-video__main p {
    margin: 20px 0;
  }
  .post-video__header {
    padding: 25px 15px;
    font-size: 16px;
  }
  .post-video__date,
  .post-video__tags,
  .post-video__share {
    font-size: 16px;
  }
  .timecode__item {
    padding: 15px 20px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .custom-results {
    margin-top: -80px;
    padding: 40px 30px 0;
  }
  .custom-results .section-title {
    margin-bottom: 30px;
  }
  .custom-results__item {
    font-size: 14px;
  }
  .custom-results__num {
    margin-bottom: 30px;
    font-size: 42px;
  }
  .custom-results__num:before {
    bottom: -11px;
  }
  .custom-results__item {
    margin-bottom: 35px;
    max-width: unset;
  }
  .image-holder-custom {
    margin-top: 38px;
    padding-bottom: 0;
  }
  .image-holder-custom img {
    position: relative;
    top: -78px;
    left: 50%;
    transform: translateX(-40%);
  }
  .image-holder-custom-two {
    margin-top: 50px;
    max-height: 655px;
  }
  .image-holder-custom-two img {
    position: relative;
    top: 50px;
  }
  .post-video-header .pulse-button {
    top: 50%;
    right: 50%;
    transform: translate(-50%, -7 0%);
  }
  .post-video__info {
    width: 100%;
    padding-right: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .post-video__main {
    width: 100%;
    padding: 0 15px;
  }
  .post-video__header {
    width: 50%;
    margin-bottom: 0;
  }
  .post-video__more {
    width: 50%;
    padding-left: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .post-video__header,
  .post-video__tags,
  .post-video__date,
  .post-video__main,
  .post-video__share {
    font-size: 14px;
  }
  .post-video-content .post-video__share {
    padding-left: 0px;
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
  }
  .post-video-content .post-video__share .post-video__link {
    width: 35px;
    height: 35px;
    margin-bottom: 5px;
  }
  .timecode__item {
    padding: 15px 15px;
    font-size: 14px;
  }
  .custom-results {
    margin-top: 0;
    border: none;
  }
  .custom-results__num {
    margin-bottom: 20px;
    font-size: 38px;
  }
  .custom-results__num:before {
    bottom: -8px;
  }
}
@media only screen and (max-width: 480px) {
  .post-video-header {
    min-height: 200px;
  }
  .post-video__header,
  .post-video__more {
    width: 100%;
    margin: 10px 0;
  }
  .post-video__more {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }
  .post-video__date,
  .post-video__tags,
  .post-video__share {
    flex: 1;
    display: block;
  }
  .post-video__date .name,
  .post-video__tags .name,
  .post-video__share .name {
    margin-bottom: 5px;
  }
  .post-video__date .name span,
  .post-video__tags .name span,
  .post-video__share .name span {
    display: none;
  }
  .post-video__header {
    font-size: 13px;
    padding: 15px;
  }
  .post-video__main p {
    margin: 15px 0;
  }
  .post-video-content .post-video__share {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1199px) {
  .page-wrap--third-page section.page-banner {
    padding-bottom: 80px;
  }
  .page-wrap--third-page .page-banner__image-wrapper {
    max-width: 500px;
  }
  .page-wrap--third-page .page-banner-wrapper {
    max-width: 420px;
  }
}
@media only screen and (max-width: 991px) {
  section.page-banner {
    padding-bottom: 50px;
  }
  .page-banner-wrapper {
    margin-top: 15px;
  }
  .page-wrap--third-page section.page-banner {
    padding-bottom: 60px;
  }
  .page-wrap--third-page section.page-banner .container {
    display: flex;
    justify-content: space-between;
  }
  .page-wrap--third-page .page-banner-wrapper {
    position: relative;
    top: 0;
    max-width: 350px;
    transform: none;
  }
  .page-wrap--third-page .page-banner__image-wrapper {
    max-width: 310px;
  }
  .page-wrap--third-page .page-banner__image-wrapper img {
    min-height: 100%;
    object-fit: cover;
  }
  .page-wrap--third-page .page-banner__title {
    font-size: 34px;
  }
  .page-wrap--third-page .page-banner__title .red {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .page-banner__title {
    font-size: 30px;
  }
  .page-banner__title .red {
    font-size: 24px;
  }
  .page-wrap--third-page section.page-banner .container {
    flex-direction: column;
  }
  .page-wrap--third-page .page-banner-wrapper {
    max-width: 100%;
  }
  .page-wrap--third-page .page-banner__image-wrapper {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .page-wrap--third-page .page-banner__title {
    font-size: 30px;
  }
  .page-wrap--third-page .page-banner__title .red {
    font-size: 24px;
  }
}
@media only screen and (max-width: 575px) {
  .page-wrap--third-page section.page-banner {
    padding-bottom: 40px;
  }
}
.page-banner_customer .page-banner-wrapper {
  right: unset;
  left: 50%;
}
.page-banner_customer .container {
  flex-wrap: wrap;
}
.page-banner-link {
  width: 100%;
  margin-top: 60px;
  text-align: center;
}
#canvas-balls-customer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.grey-bg {
  padding: 31px 0px 35px;
  background-color: #f4f4f4;
}
.grey-bg__text {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #544b5c;
}
.price {
  padding: 70px 0;
  background: #fff;
}
.price .section-title {
  margin-bottom: 40px;
}
.price__block {
  margin-bottom: 38px;
  max-width: 400px;
}
.price__block .title {
  margin-bottom: 13px;
  font-family: 'ProximaNova', sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
  color: #000000;
}
.price__block .text {
  font-family: 'ProximaNova', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #4a4a4a;
}
.price__block .text .red {
  color: #ff0048;
}
.price__block .text p + p {
  margin-top: 25px;
}
.price__block .list {
  list-style: none;
}
.price__block .list li {
  position: relative;
  padding-left: 38px;
  font-size: 16px;
  line-height: 1.06;
  color: #4a4a4a;
}
.price__block .list li:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 24px;
  height: 24px;
  background: url("/assets/media/img/check-icon.svg") no-repeat center center;
  background-size: contain;
}
.price__block .list li + li {
  margin-top: 25px;
}
.price .grey-bg {
  margin-top: 32px;
}
.company_customer {
  position: relative;
  z-index: 1;
}
.service-list_acquisition .service-list-row {
  padding: 0 0 160px;
}
.service-list_acquisition .service-list-row:first-child .service-list-row__number {
  top: -100px;
}
.service-list_acquisition .service-list-row:first-child:not(.not-connections)::before,
.service-list_acquisition .service-list-row:first-child:not(.not-connections)::after {
  display: none;
}
.service-list_acquisition .service-list-row:first-child:not(.not-connections) .service-list-item::after {
  width: 15px;
  height: 15px;
  top: 113px;
}
.service-list_acquisition .service-list-row:last-child:not(.not-connections) .service-list-item::after {
  width: 15px;
  height: 15px;
  top: 113px;
}
.service-list_acquisition .service-list-row--image-right .service-list-item__image {
  top: 60px;
}
.service-list_acquisition .service-list-row--image-right .service-list-item {
  padding: 80px 130px 70px 80px;
}
.service-list_acquisition .service-list-row--image-right .service-list-item:before {
  display: block;
  content: '';
  width: 2000px;
  height: 1px;
  position: absolute;
  top: 120px;
  right: calc(100% - 60px);
  background-color: #ff0048;
}
.service-list_acquisition .service-list-row--image-right .service-list-item:after {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  background-color: #ff0048;
  position: absolute;
  top: 113px;
  right: calc(100% - 60px);
  border-radius: 50%;
}
.service-list_acquisition .service-list-row--image-left .service-list-row__number {
  top: -80px;
}
.service-list_acquisition .service-list-row--image-left .service-list-item {
  padding: 80px 60px 100px 120px;
}
.service-list_acquisition .service-list-row--image-left .service-list-item__image {
  top: -40px;
}
.service-list_acquisition .service-list-row--image-left .service-list-item:before {
  display: block;
  content: '';
  width: 2000px;
  height: 1px;
  position: absolute;
  top: 120px;
  left: calc(100% - 60px);
  background-color: #ff0048;
}
.service-list_acquisition .service-list-row--image-left .service-list-item:after {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  background-color: #ff0048;
  position: absolute;
  top: 113px;
  left: calc(100% - 60px);
  border-radius: 50%;
}
.service-list_acquisition .service-list-row:nth-child(2n+3) .service-list-item__image {
  top: -30px;
}
.service-list_acquisition .service-list-row__number {
  font-size: 250px;
  font-weight: 900;
  line-height: normal;
  color: #ff0048;
}
.service-list_acquisition .service-list-row:last-child {
  padding-bottom: 0;
}
@media only screen and (min-width: 1200px) {
  .page-banner-link {
    width: 100%;
    margin-top: 36px;
    text-align: center;
  }
  .services-list_acquisition .service-list-item {
    min-height: 355px;
  }
  section.service-result.service-result_customer {
    margin-top: -100px;
    padding-top: 219px;
  }
  section.service-result.service-result_customer:before {
    top: 86px;
    height: 79px;
  }
  section.service-result.service-result_customer:after {
    top: 158px;
    width: 15px;
    height: 15px;
  }
  section.service-result.service-result_customer.service-result_restaurant {
    margin-top: 0px;
    padding: 96px 0;
  }
  section.service-result.service-result_customer.service-result_restaurant .service-result__title {
    margin-bottom: 36px;
  }
  section.service-result.service-result_customer.service-result_restaurant:before,
  section.service-result.service-result_customer.service-result_restaurant:after {
    display: none;
  }
}
@media only screen and (max-width: 1199px) {
  .company_customer img {
    max-width: 70%;
  }
  .service-list_acquisition .service-list-row {
    max-width: 991px;
  }
  .service-list_acquisition .service-list-row--image-right .service-list-item {
    padding: 60px 100px 60px;
    min-height: unset;
  }
  .service-list_acquisition .service-list-row--image-left .service-list-item {
    padding: 60px 40px 60px 120px;
  }
  section.service-result.service-result_customer.service-result_restaurant {
    margin-top: 0px;
    padding: 96px 0;
  }
  section.service-result.service-result_customer.service-result_restaurant .service-result__title {
    margin-bottom: 36px;
  }
  section.service-result.service-result_customer.service-result_restaurant:before,
  section.service-result.service-result_customer.service-result_restaurant:after {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .page-banner_customer .page-banner-wrapper {
    position: static;
    left: 50%;
  }
  .grey-bg {
    padding: 25px 0;
  }
  .company_customer img {
    transform: translateX(-20%);
  }
  .service-list_acquisition .service-list-row {
    max-width: 767px;
    padding-bottom: 100px;
  }
  .service-list_acquisition .service-list-row__number {
    font-size: 200px;
  }
  section.service-result.service-result_customer.service-result_restaurant {
    margin-top: 0px;
    padding: 84px 0;
  }
}
@media only screen and (max-width: 767px) {
  .page-banner-link {
    margin-top: 24px;
    text-align: left;
  }
  .services-list__title_acquisition {
    margin-bottom: 30px;
  }
  .services-list__title_acquisition:before {
    bottom: -15px;
  }
  .service-list__text {
    max-width: 500px;
  }
  .service-list_acquisition .service-list-row {
    max-width: 100%;
    padding: 0px 0px 30px;
  }
  .service-list_acquisition .service-list-row .service-list-item__title {
    font-size: 20px;
  }
  .service-list_acquisition .service-list-row .service-list-item {
    padding: 30px 0px !important;
  }
  .service-list_acquisition .services-list-item {
    min-height: 100px;
    max-width: 575px;
    margin: 0 auto;
    text-align: center;
  }
  .service-list_acquisition .services-list-item__image {
    display: block;
    position: static;
    margin: 0 auto;
  }
  .service-list_acquisition .services-list-item--image-left .service-list-item,
  .service-list_acquisition .services-list-item--image-right .service-list-item {
    padding: 30px 0px;
  }
  .services-list_results .services-list__item {
    padding-left: 0px;
    padding-top: 55px;
  }
  .services-list_results .services-list__item img {
    max-height: 50px;
    object-fit: contain;
  }
  section.service-result.service-result_customer.service-result_restaurant {
    margin-top: 60px;
    padding: 48px 0;
  }
  section.service-result.service-result_customer.service-result_restaurant .service-result__title {
    margin-bottom: 24px;
  }
  .company_customer img {
    display: none;
  }
  .company_customer .company__text-container {
    padding-left: 0;
  }
  .company_customer .company__text {
    max-width: 100%;
    margin-bottom: 80px;
  }
  .company-numbers_acquisition .company-numbers__wrapper {
    transform: translateY(-50px);
  }
  .service-list-row--image-left .service-list-item {
    margin-left: 0;
  }
}
@media only screen and (max-width: 575px) {
  .grey-bg__text {
    font-size: 14px;
  }
  .company_customer .company__text-container {
    padding-left: 15px;
  }
  .hiddentext {
    padding: 35px 0;
  }
  .hiddentext__text {
    font-size: 14px;
  }
  .service-list_acquisition .service-list-row .service-list-item {
    max-width: 100%;
    padding: 0 15px !important;
  }
  .service-list_acquisition .service-list-row .service-list-item__image {
    margin-left: 15px;
    margin-bottom: 20px;
    max-width: calc(100% - 30px);
  }
  section.service-result {
    padding: 100px 0 40px;
  }
  .price {
    padding: 40px 0;
  }
}
