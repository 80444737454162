@import "../structure/variables";

.faq-page {
  background: #E5E5E5;
}

.faq {
  &__title {
    text-align: center;
    font-weight: 800;
    font-size: 44px;
    line-height: normal;
    text-transform: uppercase;
    color: @black;

    @media screen and (max-width: 992px) {
      font-size: 36px;
    }

    @media screen and (max-width: 767px) {
      font-size: 30px;
    }

    @media screen and (max-width: 575px) {
      font-size: 24px;
    }
  }

  &__subtitle {
    margin-bottom: 40px;
    font-weight: normal;
    font-size: 36px;
    line-height: normal;
    color: #FF0048;
    text-align: center;

    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
      font-size: 26px;
    }

    @media screen and (max-width: 767px) {
      font-size: 22px;
    }

    @media screen and (max-width: 575px) {
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
}

.faq-block {

  &__title {
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: normal;
    color: @black;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0);

    &.is-active {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media screen and (max-width: 992px) {
      font-size: 20px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }

    @media screen and (max-width: 575px) {
      font-size: 16px;
    }
  }

  &__list {
    margin: 20px auto 60px;
    padding: 0 40px;
    background: #ffffff;

    @media screen and (max-width: 992px) {
      margin: 15px auto 50px;
      padding: 0 30px;
    }

    @media screen and (max-width: 575px) {
      margin: 15px auto 30px;
      padding: 0 15px;
    }
  }

  &__item {
    padding: 0px 200px 0px 100px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.3);

    transition: all .9s ease;

    &:last-child {
      border-bottom: 0;
    }

    &.is-active {
      padding-bottom: 30px;
      transition: all .5s ease-in-out;

      .faq-block__question:after {
        transform: rotate(180deg);
        transition: 0.5s ease;
      }

      .faq-block__answer {
        max-height: 9999px;
        transition: all .5s ease-in-out;
      }
    }

    @media screen and (max-width: 992px) {
      padding: 0px 80px 0px 60px;
    }

    @media screen and (max-width: 767px) {
      padding: 0 50px 0 40px;
    }

    @media screen and (max-width: 575px) {
      padding: 0 20px 0 30px;
    }
  }

  &__question {
    position: relative;
    padding: 20px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
    color: #000000;

    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 27.5px;
      left: -46px;
      width: 16px;
      height: 3px;
      background: @red_main;

      @media screen and (max-width: 992px) {
        left: -35px;
      }

      @media screen and (max-width: 767px) {
        top: 21.5px;
        left: -30px;
        width: 13px;
      }
      @media screen and (max-width: 575px) {
        top: 18.5px;
        left: -22px;
      }
    }

    &:after {
      transform: rotate(90deg);
      transition: 0.5s ease;
    }

    @media screen and (max-width: 767px) {
      padding: 15px 0;
      font-size: 14px;
    }

    @media screen and (max-width: 575px) {
      padding: 12px 0;
    }
  }

  &__answer {

    position: relative;
    padding-left: 30px;
    max-height: 0;
    overflow: hidden;
    transition: all .9s ease;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    color: #544B5C;

    ul, ol {
      padding-left: 20px;
      li {
        margin-bottom: 0.25em;
      }
    }

    & > * {
      margin-bottom: 0.5em;
    }

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      bottom: 14px;
      display: block;
      width: 1px;
      height: 100%;
      background: @red_main;
    }

    @media screen and (max-width: 992px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

}