@import "../structure/variables";



.page-wrap--about-company-page{
  .page-banner__title {
    .normal {
      text-transform: none;
      margin-bottom: 10px;
    }
  }
  section.structure {
    padding-bottom: 140px;
    .structure__link{
      display: none;
    }
  }
}


section.partners--about-company {
  padding-bottom: 0;
}
.company-numbers__wrapper--about-company {
  margin-bottom: 30px;
}

/*
section.structure--about-company {
  padding: 160px 0;
}
*/


section.about-content {
  padding-bottom: 80px;
  background-color: @white;
}
.about-content__wrapper {
  padding-top: 70px;
  max-width: 400px;
}
.about-content__title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 30px;
}
.about-content__text {
  color: #544b5c;
  line-height: 1.5;
}
.about-content__image {
  margin-top: -50px;
  margin-left: auto;
}




@media only screen and (max-width: 991px) {
  .page-wrap--about-company-page {
    section.structure {
      padding-bottom: 120px;
    }
  }
  section.about-content {
    padding: 60px 0;
    .row {
      align-items: center;
    }
  }
  .about-content__image {
    margin-top: 0;
  }
  .about-content__wrapper {
    padding: 0;
  }
}


@media only screen and (max-width: 767px) {
  .page-wrap--about-company-page {
    section.structure {
      padding-bottom: 20px;
    }
  }
  section.about-content {
    padding: 30px 0 40px;
  }
  .about-content__image {
    margin-bottom: 15px;
  }
  .about-content__title {
    margin-bottom: 15px;
  }
}