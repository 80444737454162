@import "../structure/variables";

section.vacancies-main {
  padding-top: 70px;
  padding-bottom: 80px;
}

.vacancies-main__title {
  font-size: 44px;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.vacancies-main_subtitle {
  font-size: 36px;
  line-height: 1;
  color: @red_main;
  margin-bottom: 60px;
}

.vacancies-main__text {
  p {
    color: #544b5c;
    margin-bottom: 20px;
    line-height: 1.5;
  }
}

section.vacancies {
  padding-bottom: 25px;
}

.vacancies__item {
  padding: 60px 0 50px;
  border-top: 1px solid #979797;
}

.vacancies__title {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.25;
}

.vacancies__text {
  color: #544b5c;
  line-height: 1.5;
  margin-bottom: 40px;
}

.vacancies__link {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #544b5c;
  padding: 18px 20px 15px;
  text-align: center;
  min-width: 230px;
  border-radius: 100px;
  background-color: rgba(84, 75, 92, 0.15);
  transition: all 0.5s;
}

section.vacancy-apply {
  background-color: #f5f2f6;
  padding: 80px 0;
}

.vacancy-apply__title {
  font-size: 23px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 60px;
}

.vacancy-form__row {
  margin-bottom: 40px;
}

.vacancy-form__field {
  margin-bottom: 20px;
  position: relative;
}

.vacancy-form__label {
  color: #544b5c;
  line-height: 24px;
  display: flex;
  margin-bottom: 4px;
  span {
    font-size: 30px;
    color: #f9593a;
    line-height: 24px;
  }
}

.vacancy-form__input {
  display: block;
  width: 100%;
  height: 52px;
  background-color: rgba(215, 204, 219, 0.46);
  padding: 0 50px 0 15px;
  font-size: 14px;
  color: #000000;
  border: none;
  line-height: 52px;
  text-indent: 30px;
}

.vacancy-form__field_resume input {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}

.vacancy-form__field_resume .vacancy-form__input{
  position: relative;
  cursor: pointer;
}
.vacancy-form__field_resume .vacancy-form__input svg{
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.vacancy-form__input--textarea {
  height: 150px;
  padding: 10px 15px;
}

.vacancy-form__required-message {
  font-size: 13px;
  color: #9fa3a7;
  line-height: 13px;
  display: flex;
  span {
    font-size: 30px;
    color: #f9593a;
    line-height: 13px;
    margin-right: 5px;
  }
}

.vacancy-form__button-wrapper {
  text-align: center;
}

.vacancy-form__button {
  padding: 13px;
  min-width: 200px;
}

@media only screen and (min-width: 992px) {
  .vacancies__link:hover {
    color: @white;
    background-color: #000000;
  }
}

@media only screen and (max-width: 991px) {
  section.vacancies-main {
    padding-top: 20px;
  }

  .vacancies-main__title {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .vacancies-main__title {
    font-size: 28px;
  }

  .vacancies-main_subtitle {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .vacancies-main__image {
    margin-bottom: 15px;
  }

  section.vacancies-main {
    padding-bottom: 30px;
  }

  .vacancies__item {
    padding: 40px 0;
  }

  .vacancies__title {
    margin-bottom: 15px;
  }

  section.vacancy-apply {
    padding: 50px 0;
  }

  .vacancy-apply__title {
    font-size: 20px;
    margin-bottom: 40px;
  }

  .vacancy-form__input {
    height: 42px;
  }
}