@import "../structure/variables";


.page-wrap--company {
  section.reviews {
    background-color: @white;
  }
}


section.partners {
  padding-top: 100px;
  padding-bottom: 70px;
  background-color: @white;
  .company__link--video {
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: -31px;
    margin-top: -26px;
  }
}
.partners-row {
  margin-bottom: 50px;
}
.section-title--partners {
  padding-top: 30px;
  padding-bottom: 25px;
  text-align: left;
}
.partners-text {
  color: #544b5c;
  line-height: 1.5;
  max-width: 425px;
}
.partners__image {
  margin-left: auto;
}
.company-numbers__wrapper--partners {
  margin: 0 auto 65px;
  box-shadow: none;
}
.partners-button-wrapper {
  text-align: center;
}
.partners-button {
  color: #544b5c;
  font-size: 12px;
  font-weight: 700;
  padding: 18px 20px 15px;
  min-width: 230px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 100px;
  border: solid 1px #000000;
  display: inline-block;
  transition: all 0.5s;
}



section.principles {
  padding-top: 70px;
  background-color: @white;
}
.section-title--principles {
  margin-bottom: 50px;
}
.principles-wrapper {
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  align-items: center;  
}
.principles-navigation {
  flex: 0 0 50%;
  max-width: 50%;
}
.principles-navigation__item {
  margin-bottom: 60px;
  padding-left: 100px;
  position: relative;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
}
.principles-navigation__item--active {
  z-index: 2;
  &::before {
    display: block;
    content: '';
    width: 75px;
    height: 1px;
    background-color: @red_main;
    position: absolute;
    top: 8px;
    right: -30px;
  }
  /*&::after {
    display: block;
    content: '';
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: @red_main;
    position: absolute;
    top: 3px;
    right: -30px;
  }*/
  .principles-navigation__text {
    font-weight: 700;
  }
  .principles-navigation__image--off {
    opacity: 0;
  }
  .principles-navigation__image--on {
    opacity: 1;
  }
}
.principles-navigation__text {
  font-size: 14px;
  line-height: 1.43;
  max-width: 300px;
  transition: all 0.5s;
}
.principles-navigation__image {
  position: absolute;
  top: 0;
  left: 0;
  /*opacity: 0.3;*/
  transition: all 0.5s;
  max-width: 60px;
}
.principles-navigation__image--on {
  opacity: 0;
}
.principles-navigation__image--off {
  opacity: 1;
}
.principles-list {
  flex: 0 0 50%;
  max-width: 50%;
  height: 480px;
  box-shadow: 4px 4px 13px 0 rgba(182, 173, 189, 0.47);
  position: relative;
}
.principles-list__item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 70px 50px 100px;
  background-color: @white;
  z-index: 0;
  transition: all 0.5s;
  p {
    color: #544b5c;
    line-height: 1.5;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.principles-list__item--active {
  z-index: 1;
}
.principles-list__image {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  max-width: 180px;
}
.principles-list__link {
  position: absolute;
  left: 50px;
  bottom: 60px;
  span {
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    margin-right: 10px;
    color: #544b5c;
    transition: all 0.5s;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    transition: all 0.5s;
  }
}

section.principles-image {
  background: #fff;
  padding-top: 150px;
}

.principles__image {
  margin: 0 auto -60px;
}




section.structure {
  padding-top: 160px;
  padding-bottom: 120px;
  background-color: #f5f3f6;
}
.structure__title {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.structure__text {
  color: #544b5c;
  line-height: 1.5;
  margin-bottom: 40px;
}
.structure__link {
  span {
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    margin-right: 10px;
    transition: all 0.5s;
    color: #000000;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    transition: all 0.5s;
  }
}

.structure-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
}
.structure-block {
  flex: 0 0 230px;
  max-width: 230px;
}
.structure-block--center {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: @red_main;
    position: absolute;
    left: 0;
    top: 35px;
    z-index: 0;
  }
}
.structure-block__image {
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  background-color: #f5f3f6;
}
.structure-block__title {
  color: #544b5c;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.25;
  text-align: center;
}
.structure-block__item {
  background-color: @white;
  padding: 20px;
  color: #544b5c;
  font-size: 12px;
  line-height: 1.25;
  box-shadow: 4px 4px 13px 0 rgba(182, 173, 189, 0.47);
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &::before {
    display: block;
    content: '';
    height: calc(~"100% + 20px");
    width: 1px;
    background-color: @red_main;
    position: absolute;
    top: 0;
  }
  &::after {
    display: block;
    content: '';
    width: 30px;
    height: 1px;
    background-color: @red_main;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &:first-child {
    &::before {
      height: calc(~"50% + 20px");
      top: 50%;
    }
  }
  &:last-child {
    &::before {
      height: 50%;
    }
  }
}
.structure-block--right {
  padding-left: 30px;
  .structure-block__item {
    &::after {
      right: 100%;
    }
    &::before {
      left: -30px;
    }
  }
}
.structure-block--left {
  padding-right: 30px;
  .structure-block__item {
    &::after {
      left: 100%;
    }
    &::before {
      right: -30px;
    }
  }
}
.structure-wrapper-mobile {
  display: none;
}


section.vacancies-list {
  background-color: #f4f4f4;
  padding-top: 110px;
  padding-bottom: 110px;
  position: relative;
}
.section-title--vacancies-list {
  font-size: 23px;
  text-align: left;
  padding-top: 40px;
  &::after {
    display: block;
    width: 50px;
    content: '';
    height: 1px;
    background-color: #ff5464;
    margin: 25px 0 30px;
  }
}
.vacancies-list-row {
  min-height: 300px;
}
.vacancies-list__text {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.55;
  max-width: 360px;
  margin-bottom: 60px;
}
.vacancies-list__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.vacancies-list__item {
  width: 300px;
  min-height: 370px;
  padding: 50px 30px 50px 60px;
  position: relative;
  transition: all 0.5s;
}
.vacancies-list__item-wrapper {
  position: relative;
}
.vacancies-list__image {
  width: auto !important;
  position: absolute;
  top: 0;
  left: 0;
}
.vacancies-list__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  margin-bottom: 10px;
  padding-top: 140px;
}
.vacancies-list__text--item {
  transition: all 0.3s;
  margin-bottom: 20px;
}
.vacancies-list__show-more {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.5s;
  span {
    margin-right: 10px;
  }
  svg {
    margin-top: -2px;
  }
}
.vacancies-list__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blog-list__read-more-wrapper--left {
  text-align: left;
}
.vacancies-image {
  display: none;
  margin-left: auto;
}









@media only screen and (min-width: 992px) {
  .partners-button:hover {
    background-color: #000000;
    color: @white;
  }
  .principles-list__link:hover {
    span {
      color: @red_main;
    }
    svg {
      transform: translateX(10px);
    }
  }
  .structure__link:hover {
    span {
      color: @red_main;
    }
    svg {
      transform: translateX(10px);
    }
  }
  .vacancies-list__item:hover {
    box-shadow: 0 0 19px 0 rgba(130, 130, 130, 0.21);
    background-color: @white;
    .vacancies-list__show-more {
      opacity: 1;
    }
  }
}


@media only screen and (max-width: 991px) {
  section.partners {
    padding: 60px 0;
  }
  .section-title--partners {
    padding-top: 0;
  }
  section.principles {
    padding-top: 50px;
  }
  .principles-wrapper {
    margin-bottom: 100px;
  }
  .principles-navigation__item {
    margin-bottom: 35px;
    padding-left: 60px;
    padding-right: 20px;
  }
  .principles-navigation__item--active::before {
    right: -10px;
    top: auto;
    bottom: -10px;
  }
  .principles-navigation__image {
    max-width: 45px;
  }
  .principles-list {
    height: 450px;
  }
  .principles-list__link {
    left: 30px;
    bottom: 50px;
  }
  .principles-list__item {
    padding: 30px;
  }
  .principles-list__image {
    max-width: 100px;
  }
  .principles__image {
    margin-bottom: 0;
  }
  section.structure {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .structure-wrapper {
    padding-left: 0;
  }
  .structure__link {
    display: inline-block;
    margin-bottom: 30px;
  }
  section.vacancies-list {
    padding: 60px 0;
  }
  .vacancies-list__item {
    box-shadow: 0 0 19px 0 rgba(130,130,130,.21);
    background-color: #fff;
    &:last-of-type {
      display: none;
    }
  }
  .vacancies-list__wrapper {
    justify-content: center;
  }
  .vacancies-list__show-more {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px)  {
  section.partners {
    padding: 30px 0;
  }
  .partners__image {
    margin-bottom: 20px;
  }
  .partners-row {
    margin-bottom: 20px;
  }
  .company-numbers__wrapper--partners {
    margin-bottom: 40px;
  }

  section.principles-image{
    padding-top:50px;
  }
  .principles-list {
    display: none;
  }
  .principles-navigation {
    flex: auto;
    max-width: 100%;
  }
  .principles-navigation__item {
    padding-right: 0;
  }
  .principles-navigation__image--on {
    opacity: 1;
  }
  .principles-navigation__image--off {
    opacity: 0;
  }
  .principles-navigation__item--active {
    .principles-navigation__text {
      font-weight: 400;
    }
    &::before {
      display: none;
    }
  }
  .principles-wrapper {
    margin-bottom: 0px;
  }
  section.structure {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .structure-wrapper {
    display: none;
  }
  .structure__link {
    display: none;
  }
  section.vacancies-list {
    padding: 40px 0;
  }
  .section-title--vacancies-list {
    padding-top: 0;
    &::after {
      margin: 15px 0 30px;
    }
  }
  .vacancies-list__text {
    margin-bottom: 30px;
  }
  .vacancies-list__wrapper {
    padding-top: 30px;
    justify-content: flex-start;
  }


  .structure-wrapper-mobile {
    display: block;
    text-align: center;
    .structure-block__title {
      margin-bottom: 15px;
      font-size: 14px;
    }
    .structure-block__image {
      margin: 0 auto 30px;
    }
    .structure-block-mobile {
      background-color: @white;
      box-shadow: 4px 4px 13px 0 rgba(182, 173, 189, 0.47);
      border-radius: 4px;
      padding: 35px 50px 45px;
      max-width: 240px;
      margin: 0 auto 30px;
      position: relative;
      &::before {
        display: block;
        content: '';
        width: 1px;
        height: 40px;
        background-color: @red_main;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .structure-block-mobile__item {
      font-size: 12px;
      color: #544b5c;
      line-height: 1.3;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .structure__link {
      display: inline-block;
    }
  }
}

// ==== animation ====

.structure-wrapper {
  .structure-block--center {
    &:before {
      opacity: 1;
      transition: @transition_company_relation;
    }
    svg {
      opacity: 1;
      transform: scale(1);
      transition: opacity .5s linear, transform 0.5s ease;
      path:not(:first-child) {
        transition: transform 0.5s 0.3s ease;
        transform: translateY(0px);
      }
    }
    .structure-block__title {
      opacity: 1;
      transition: opacity 0.5s 0.8s linear;
    }
  }
  .structure-block__item {
    transition: opacity .5s linear, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0px);
    &:before,
    &:after {
      opacity: 1;
      transition: @transition_company_relation;
    }
  }
  .structure-block--right {
    .structure-block__item {
      &:nth-child(1) {
        transition-delay: 1.2s;
      }
      &:nth-child(2) {
        transition-delay: 1.6s;
      }
      &:nth-child(3) {
        transition-delay: 2s;
      }
      &:nth-child(4) {
        transition-delay: 2.4s;
      }
      &:nth-child(5) {
        transition-delay: 2.8s;
      }
    }
  }
  .structure-block--left {
    .structure-block__item {
      &:nth-child(1) {
        transition-delay: 1.4s;
      }
      &:nth-child(2) {
        transition-delay: 1.8s;
      }
      &:nth-child(3) {
        transition-delay: 2.2s;
      }
      &:nth-child(4) {
        transition-delay: 2.6s;
      }
    }
  }
  &.animate {
    .structure-block--center {
      &:before {
        opacity: 0;
      }
      svg {
        opacity: 0;
        transform: scale(0.2);
        path:not(:first-child) {
          transform: translateY(60px);
        }
      }
    }
    .structure-block__title {
      opacity: 0;
    }
    .structure-block__item {
      opacity: 0;
      transform: translateY(40px);
      &:before,
      &:after {
        opacity: 0;
      }
    }
  }
}
