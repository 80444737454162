@import "variables";

.page-banner_customer .page-banner-wrapper {
  right: unset;
  left: 50%;
}

.page-banner_customer .container {
  flex-wrap: wrap;
}

.page-banner-link {
  width: 100%;
  margin-top: 60px;
  text-align: center;
}

#canvas-balls-customer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.grey-bg {
  padding: 31px 0px 35px;
  background-color: #f4f4f4;

  &__text {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: #544b5c;
  }
}

.price {
  padding: 70px 0;
  background: @white;

  .section-title {
    margin-bottom: 40px;
  }

  &__block {
    margin-bottom: 38px;
    max-width: 400px;

    .title {
      margin-bottom: 13px;
      font-family: 'ProximaNova', sans-serif;
      font-size: 20px;
      font-weight: 800;
      line-height: normal;
      color: #000000;
    }

    .text {
      font-family: 'ProximaNova', sans-serif;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #4a4a4a;

      .red {
        color: @red_main;
      }

      p + p {
        margin-top: 25px;
      }
    }

    .list {
      list-style: none;

      li {
        position: relative;
        padding-left: 38px;
        font-size: 16px;
        line-height: 1.06;
        color: #4a4a4a;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          display: block;
          width: 24px;
          height: 24px;
          background: url("/assets/media/img/check-icon.svg") no-repeat center center;
          background-size: contain;
        }

        & + li {
          margin-top: 25px;
        }
      }
    }

  }

  .grey-bg {
    margin-top: 32px;
  }
}

.company_customer {
  position: relative;
  z-index: 1;
}

.services-list_hidden {

}

.service-list_acquisition {
  .service-list-row {
    padding: 0 0 160px;

    &:first-child {
      .service-list-row__number {
        top: -100px;
      }

      &:not(.not-connections)::before,
      &:not(.not-connections)::after {
        display: none;
      }

      &:not(.not-connections) .service-list-item::after {
        width: 15px;
        height: 15px;
        top: 113px;
      }
    }

    &:last-child {
      &:not(.not-connections) .service-list-item::after {
        width: 15px;
        height: 15px;
        top: 113px;
      }
    }

    &--image-right {

      .service-list-item__image {
        top: 60px;
      }

      .service-list-item {
        padding: 80px 130px 70px 80px;

        &:before {
          display: block;
          content: '';
          width: 2000px;
          height: 1px;
          position: absolute;
          top: 120px;
          right: calc(~"100% - 60px");
          background-color: #ff0048;
        }

        &:after {
          display: block;
          content: '';
          width: 15px;
          height: 15px;
          background-color: #ff0048;
          position: absolute;
          top: 113px;
          right: calc(~"100% - 60px");
          border-radius: 50%;
        }
      }
    }

    &--image-left {
      .service-list {

        &-row__number {
          top: -80px;
        }

        &-item {
          padding: 80px 60px 100px 120px;

          &__image {
            top: -40px;
          }
          &:before {
            display: block;
            content: '';
            width: 2000px;
            height: 1px;
            position: absolute;
            top: 120px;
            left: calc(~"100% - 60px");
            background-color: #ff0048;
          }

          &:after {
            display: block;
            content: '';
            width: 15px;
            height: 15px;
            background-color: #ff0048;
            position: absolute;
            top: 113px;
            left: calc(~"100% - 60px");
            border-radius: 50%;
          }
        }

      }

    }

    &:nth-child(2n+3) {
      .service-list-item__image {
        top: -30px;
      }
    }

    &__number {
      font-size: 250px;
      font-weight: 900;
      line-height: normal;
      color: #ff0048;
    }

    &:last-child {
      padding-bottom: 0;
    }

  }
}

@media only screen and (min-width: 1200px) {

  .page-banner-link {
    width: 100%;
    margin-top: 36px;
    text-align: center;
  }

  .services-list_acquisition {
    .service-list-item {
      min-height: 355px;
    }
  }

  section.service-result.service-result_customer {
    margin-top: -100px;
    padding-top: 219px;

    &:before {
      top: 86px;
      height: 79px;
    }

    &:after {
      top: 158px;
      width: 15px;
      height: 15px;
    }
  }

  section.service-result.service-result_customer.service-result_restaurant {
    margin-top: 0px;
    padding: 96px 0;

    .service-result__title {
      margin-bottom: 36px;
    }

    &:before,
    &:after {
      display: none;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .company_customer img {
    max-width: 70%;
  }

  .service-list_acquisition {
    .service-list-row {
      max-width: 991px;

      &--image-right .service-list-item {
        padding: 60px 100px 60px;
        min-height: unset;
      }

      &--image-left .service-list-item {
        padding: 60px 40px 60px 120px;
      }

    }
  }

  section.service-result.service-result_customer.service-result_restaurant {
    margin-top: 0px;
    padding: 96px 0;

    .service-result__title {
      margin-bottom: 36px;
    }

    &:before,
    &:after {
      display: none;
    }
  }

}

@media only screen and (max-width: 991px) {

  .page-banner_customer {
    .page-banner-wrapper {
      position: static;
      left: 50%;
    }
  }

  .grey-bg {
    padding: 25px 0;
  }

  .company_customer img {
    transform: translateX(-20%)
  }

  .service-list_acquisition {
    .service-list-row {
      max-width: 767px;
      padding-bottom: 100px;
    }

    .service-list-row__number {
      font-size: 200px;
    }
  }

  section.service-result.service-result_customer.service-result_restaurant {
    margin-top: 0px;
    padding: 84px 0;
  }

}

@media only screen and (max-width: 767px) {

  .page-banner-link {
    margin-top: 24px;
    text-align: left;
  }

  .services-list__title_acquisition {
    margin-bottom: 30px;

    &:before {
      bottom: -15px;
    }

  }

  .service-list__text {
    max-width: 500px;
  }

  .service-list_acquisition {
    .service-list-row {
      max-width: 100%;
      padding: 0px 0px 30px;

      .service-list-item__title {
        font-size: 20px;
      }

      .service-list-item {
        padding: 30px 0px !important;
      }

    }

    .services-list-item {
      min-height: 100px;

      max-width: 575px;
      margin: 0 auto;
      text-align: center;
      &__image {
        display: block;
        position: static;
        margin: 0 auto;
      }

      &--image-left .service-list-item,
      &--image-right .service-list-item {
        padding: 30px 0px;
      }

    }
  }

  .services-list_results {
    .services-list__item {
      img {
        max-height: 50px;
        object-fit: contain;
      }
      padding-left: 0px;
      padding-top: 55px;
    }
  }

  section.service-result.service-result_customer.service-result_restaurant {
    margin-top: 60px;
    padding: 48px 0;

    .service-result__title {
      margin-bottom: 24px;
    }
  }

  .company_customer {
    img {
      display: none;
    }

    .company__text-container {
      padding-left: 0;
    }

    .company__text {
      max-width: 100%;
      margin-bottom: 80px;
    }
  }

  .company-numbers_acquisition {
    .company-numbers__wrapper {
      transform: translateY(-50px)
    }
  }

  .service-list-row--image-left .service-list-item {
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {

  .grey-bg {
    &__text {
      font-size: 14px;
    }
  }

  .company_customer {
    .company__text-container {
      padding-left: 15px;
    }
  }

  .hiddentext {
    padding: 35px 0;
    &__text {
      font-size: 14px;
    }
  }

  .service-list_acquisition .service-list-row .service-list-item {
    max-width: 100%;
    padding: 0 15px !important;
  }

  .service-list_acquisition .service-list-row .service-list-item__image {
    margin-left: 15px;
    margin-bottom: 20px;
    max-width: calc(~ "100% - 30px");
  }

  section.service-result {
    padding: 100px 0 40px;
  }

  .price {
    padding: 40px 0;
  }

}