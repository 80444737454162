@import "../structure/variables";

.case-header {
  &_wrap {
    padding: 50px 0 90px;
    .page-header {
      color: #000;
      border: none;
      padding-bottom: 9px;
      margin: 40px 0 20px;
      width: 100%;
      .page-title {
        font-size: 36px;
        font-family: ProximaNova, sans-serif;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .page-subtitle {
        font-family: ProximaNova, sans-serif;
        font-weight: bold;
      }
      .description {
        margin-top: 30px;
        ul {
          margin-left: 20px;
        }
      }
      .what-have-done {
        margin-top: 80px;
        font-size: 14px;
        ul {
          margin-left: 20px;
        }
      }
      .title {
        font-family: ProximaNova, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 12px;
        font-size: 14px;
      }
      a {
        text-decoration: underline;
        color: #000;
      }
    }
  }
}

.case-result {
  &_wrap {
    padding: 65px 0 30px;
    padding-top: 0;
    .browser-wrapper {
      position: relative;
      margin-top: -90px;
      padding-bottom: 80px;
      border: 2px solid #d8d8d8;
      border-top: 27px solid #d8d8d8;
      background: #fff;
      -webkit-border-top-right-radius: 10px;
      -webkit-border-bottom-right-radius: 0;
      -webkit-border-bottom-left-radius: 0;
      -webkit-border-top-left-radius: 10px;
      -moz-border-radius-topright: 10px;
      -moz-border-radius-bottomright: 0;
      -moz-border-radius-bottomleft: 0;
      -moz-border-radius-topleft: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 10px;
      -moz-background-clip: padding;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      &:before {
        content: "\2022 \2022 \2022";
        display: block;
        position: absolute;
        left: 3px;
        top: -30px;
        font-size: 50px;
        line-height: 27px;
        color: #ffffff;
      }
      .section-title-wrap {
        margin-bottom: 65px;
        .section-title {
          font-family: ProximaNova;
          font-weight: bold;
          margin-top: 40px;
        }
      }
      .block-info {
        max-width: 350px;
        margin: 0 auto 30px;
        .radial-progress-holder {
          padding: 25px 0;
        }
        .radial-progress {
          margin: 0 0 0 10px;
          width: 160px;
          height: 160px;
          background-color: #ededed;
          border-radius: 50%;
          margin: 0 auto;
          .circle {
            .mask {
              clip: rect(0px, 160px, 160px, 80px);
              .fill {
                clip: rect(0px, 80px, 160px, 0px);
                background-color: #ff3548;
              }
            }
            .shadow {
              width: 160px;
              height: 160px;
              position: absolute;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;
              -moz-background-clip: padding;
              -webkit-background-clip: padding-box;
              background-clip: padding-box;
            }
          }
        }
        .inset {
          width: 145px;
          height: 145px;
          position: absolute;
          margin-left: 7.5px;
          margin-top: 7.5px;
          background-color: #fff;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          -moz-background-clip: padding;
          -webkit-background-clip: padding-box;
          background-clip: padding-box;
          .percentage {
            height: 44px;
            width: 190px;
            overflow: hidden;
            position: absolute;
            top: 50.5px;
            left: -22.5px;
            line-height: 1;
            .number span {
              width: 190px;
              display: block;
              vertical-align: top;
              text-align: center;
              font-size: 44px;
              font-family: ProximaNova, sans-serif;
              font-weight: bold;
              color: #544A5C;
            }
          }
        }
        .radial-progress-holder {
          padding: 25px 0;
        }
        .circle-with-info {
          display: block;
          margin: 0 auto;
          width: 160px;
          height: 160px;
          color: #fff;
          background: #ff3548;
          font-family: ProximaNova, sans-serif;
          font-weight: bold;
          font-size: 44px;
          line-height: 160px;
          text-align: center;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          -moz-background-clip: padding;
          -webkit-background-clip: padding-box;
          background-clip: padding-box;
          overflow: hidden;

          .value img {
            margin: 35px auto;
          }
        }
      }
    }
  }
}

.case-screens {
  &_wrap {
    padding: 65px 0 30px;
    padding-bottom: 0;
    .section-content {
      padding: 50px 0;
    }
    .image-holder {
      padding: 50px 0;
      &.no-padding {
        padding: 0;
      }
      &.no-bg {
        background: none;
      }
      img {
        max-width: 96%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
      }
      .video_wrapper {
        video {
          max-width: 100%;
        }
      }
    }
    .icons-list-wrap {
      background: #ededed;
      padding: 50px 0;
      ul {
        padding: 0;
        margin: 0;
      }
      li {
        list-style: none;
        display: inline-block;
        padding: 20px 10px;
        width: 19%;
        margin-bottom: 10px;
        text-align: center;
        img {
          max-width: 100px;
        }
      }
    }
  }
}

.case-next {
  &_wrap {
    padding: 100px 0;
    background: rgba(0, 0, 0, 0.66);
    color: #ffffff;
    position: relative;
    background-position: center center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 0;
    }
    .next-client {
      position: relative;
      z-index: 1;
      text-align: center;
      .title {
        font-size: 36px;
        font-family: ProximaNova;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .link-holder a {
        color: #fff;
        font-size: 24px;
        padding-bottom: 30px;
        &:hover {
          color: #ff3548;
        }
        &:after {
          content: "";
          width: 30px;
          height: 20px;
          background: url(/assets/media/img/small-arrow-right-red.svg) no-repeat;
          display: block;
          margin: 10px auto 0;
        }
      }
    }
  }
}

.case-custom-results {
  &__wrapper {
    margin: -140px auto 0;
    padding: 70px 90px 0;
    width: 100%;
    max-width: 1120px;
    object-fit: contain;
    border: solid 2px #e1e1e1;
    background-color: #ffffff;
  }

  .section-title {
    margin-bottom: 70px;
    font-size: 26px;
    font-weight: bold;
    line-height: 0.92;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
}

.faradey_animation {
  position: relative;
  padding: 50px 0;
  .faradey_bolt {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  img {
    max-width: 100%;
  }
}

.pulse {
  animation: sk-scaleout 1.2s infinite ease-in-out;
}

.video_wrapper.vg_video {
  text-align: center;
  font-size: 0;
}

@media only screen and (max-width: 1200px) {
  .case-custom-results {
    &__wrapper {
      padding: 50px 70px 0;
      max-width: 970px;
      width: 100%;
    }

  }
}

@media only screen and (max-width: 991px) {
  .case-custom-results {
    &__wrapper {
      padding: 50px 50px 0;
      width: 100%;
      max-width: 767px;
    }

    .section-title {
      margin-bottom: 40px;
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .case-screens_wrap {
    padding-top: 0;
  }

  .case-screens_wrap .image-holder img {
    max-width: 100% !important;
  }

  .what-have-done {
    ul {
      margin-left: 15px;
    }
  }

  .case-result_wrap .browser-wrapper {
    border: none;
    margin-top: 0;
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }

  .case-screens_wrap .icons-list-wrap ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 50%;
      padding: 0;

      img {
        margin: 0 auto;
      }
    }
  }

  .case-custom-results {
    &__wrapper {
      margin: -90px auto 0;
      padding: 50px 20px 0;
      width: 100%;
      max-width: 575px;
    }

    .section-title {
      margin-bottom: 40px;
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 575px) {

  .case-custom-results {
    padding: 15px;
    &__wrapper {
      margin: 0px auto 0;
      padding: 50px 20px 0;
      width: 100%;
      max-width: 480px;
    }

    .section-title {
      margin-bottom: 40px;
      font-size: 20px;
    }
  }

}