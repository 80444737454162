@import "../structure/variables";

section.blog-categories {
  width: 100%;
  //max-width: 1250px;
  padding: 0 15px;
  margin: 15px auto 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-categories__link {
  font-size: 14px;
  color: #4a4a4a;
  text-transform: uppercase;
  line-height: 1.75;
  transition: all 0.5s;
  padding: 0 10px;
}
.blog-categories__link--active {
  color: @red_main;
}


section.blog-slider {
  position: relative;
  + .blog-list {
    padding-bottom: 0;
  }
}
.blog-slider__item {
  width: 100%;
  height: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-slider__text {
  max-width: 650px;
  text-align: center;
  padding-top: 80px;
}
.blog-slider__title {
  font-size: 34px;
  font-weight: 800;
  color: @white;
  line-height: 1.3;
  margin-bottom: 50px;
  padding: 0 15px;
}
.blog-slider__dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  text-align: center;
}
.blog-slider__dot {
  width: 8px;
  height: 8px;
  background-color: @white;
  border-radius: 50%;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  &.active {
    background-color: @red_main;
  }
}


.blog-categories__button-wrapper {
  padding: 10px 15px 20px;
  display: none;
}
.blog-categories__button {
  border: 1px solid #d7ccdb;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
}
.blog-categories__arrow-wrapper {
  display: none;
}




@media only screen and (min-width: 992px) {
  .blog-categories__link:hover {
    color: @red_main;
  }
  .blog-slider__dot:hover {
    background-color: @red_main;
  }
}



@media only screen and (max-width: 991px) {
  .blog-categories__link {
    font-size: 13px;
    line-height: 1.2;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .blog-slider__title {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .blog-slider__item {
    height: 350px;
  }
  .blog-slider__dots {
    bottom: 10px;
  }
  .blog-slider__link {
    padding: 11px;
    min-width: 170px;
  }
  .blog-slider__text {
    padding-top: 20px;
  }
  section.blog-categories {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    flex-direction: column;
    z-index: 2;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: auto;
    padding: 20px 15px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
  .blog-categories__link {
    font-size: 14px;
    padding: 15px;
  }
  .blog-categories__arrow-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .blog-categories__button-wrapper {
    display: block;
  }
}