@import "../structure/variables";

.post-header {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 60px;
  .category {
    background-color: #ff5464;
    padding: 5px 10px;
    font-family: 'ProximaNova', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 20px;
    display: inline-block;
    margin-right: 15px;
    a {
      color: #ffffff;
    }
  }
  &_title {
    font-family: 'ProximaNova-Extrabld', sans-serif;
    font-size: 38px;
    line-height: 1.25;
    color: #ffffff;
    margin: 0 0 20px 0;
    max-width: 610px;
  }
  .date {
    font-family: 'ProximaNova', sans-serif;
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.post-content {
  &_wrapper {
    background-color: #ffffff;
    margin-top: -110px;
    padding-top: 80px;
    position: relative;
  }

  &_content {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    color: #4a4a4a;
    font-size: 18px;
    line-height: 1.33;
    &::after {
      display: block;
      content: '';
      width: 140px;
      height: 1.5px;
      background-color: #979797;
      margin-left: auto;
      margin-right: auto;
      margin-top: 80px;
    }
    p {
      margin-bottom: 20px;
    }
    ul {
      margin-bottom: 20px;
      padding-left: 40px;
      li {
        font-size: 18px;
        line-height: 1.33;
        color: #4a4a4a;
        margin-bottom: 10px;
      }
    }
    blockquote {
      padding: 0;
      margin: 60px 0;
      border: none;
      text-align: center;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      font-size: 22px;
      color: #ff5464;
      font-family: 'ProximaNova', sans-serif;
      font-weight: 300;
      font-style: italic;
    }
    h2 {
      margin-top: 70px;
      margin-bottom: 25px;
      font-size: 24px;
      font-family: 'ProximaNova', sans-serif;
      color: #000000;
      a {
        color: #ff3548;
        text-decoration: underline;
      }
    }
    .socials {
      bottom: 195px;
      top: auto;
    }
    img,
    video {
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
  }
  .post-authors {
    text-align: center;
    padding-top: 50px;
    &_item {
      display: inline-block;
      vertical-align: middle;
      margin: 0 15px;
      min-width: 150px;
    }
  }
}

.author {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    border-radius: 50%;
    overflow: hidden;
    max-width: 82px;
  }
  &_name {
    font-family: 'ProximaNova', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: rgba(74, 74, 74, 0.65);
    line-height: 1;
    margin-bottom: 15px;
  }
  &_position {
    font-family: 'ProximaNova', sans-serif;
    font-size: 14px;
    color: rgba(74, 74, 74, 0.65);
    line-height: 1;
  }
}

article .content_wrapper .socials {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: right;
  position: absolute;
  top: 160px;
  right: 0;
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

.socials.fixed {
  top: 50%;
  -webkit-transform: translatey(-50%);
  -moz-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  -o-transform: translatey(-50%);
  transform: translatey(-50%);
  position: fixed;
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

.socials.bottom {
  bottom: 195px;
  top: auto;
}

.socials a {
  margin-bottom: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.socials a:last-child {
  margin-bottom: 0;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.post-video-header {
  position: relative;
  display: flex;
  max-height: 465px;

  .container {
    //height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  .pulse-button {
    right: 50%;
    transform: translate(50%, -50%);
  }
}

.post-video-content {
  padding-top: 30px;
}

.post-video__header {
  margin-bottom: 35px;
  padding: 30px 20px;
  background: @red_main;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.post-video__date,
.post-video__tags,
.post-video__share {
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  text-align: left;
  color: #363636;

  .name {
    font-weight: bold;
    white-space: nowrap;
  }
}

.post-video__tags {
  a {
    color: #5b5b5b;
    transition: 0.25s ease-in-out;
    &:hover {
      color: #ff3448;
      transition: 0.25s ease-in-out;
    }
  }
}

.post-video__info {
  width: 30%;
  padding-left: 15px;
  padding-right: 38px;
}

.post-video__main {
  width: 70%;
  padding-right: 220px;

  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #5b5b5b;

  p {
    margin: 25px 0;
  }
}

.post-video__share {
  margin-top: 30px;
  &_post {
    .name {
      margin-right: 20px;
    }
  }
}

.post-video__link {
  display: inline-flex;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  svg {
    width: 100%;
    height: 100%;
    .st0 {
      transition: 0.25s ease;
    }
  }
  &_fb:hover svg {
    .st0 {
      fill: #3b5998;
      transition: 0.15s ease;
    }
  }
  &_tw:hover svg {
    .st0 {
      fill: #1DA1F2;
      transition: 0.15s ease;
    }
  }
}

.timecode {
  margin-top: 15px;
  &__item {
    padding: 20px 25px;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.33;
    text-align: left;
    color: #5b5b5b;

    box-shadow: 0px 3px 27px 0 rgba(141, 141, 141, 0.16);
    span {
      font-weight: 600;
    }
  }
}

.custom-results {
  position: relative;
  margin-top: -130px;
  padding: 70px 90px 0px;
  border: solid 2px #e1e1e1;
  background: @white;

  .section-title {
    margin-bottom: 70px;
    font-size: 26px;
    font-weight: bold;
    line-height: 0.92;
    text-align: center;
    color: #000000;
  }

  &__item {
    max-width: 290px;
    margin-bottom: 70px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  &__num {
    position: relative;
    margin-bottom: 50px;
    display: block;
    font-size: 48px;
    font-weight: 600;
    color: #ff003e;

    &:before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      height: 1px;
      background-color: #ff003e;
    }
  }
}

.image-holder-custom {
  margin-top: 78px;
  padding-bottom: 0;
  img {
    position: relative;
    top: -128px;
    left: 50%;
    transform: translateX(-40%);
  }
}

.image-holder-custom-two {
  margin-top: 111px;
  max-height: 655px;
  img {
    position: relative;
    top: -111px;
  }
}

.image-custom-three {
  position: relative;
  left: 50%;
  transform: translateX(-40%)
}

@media only screen and (max-width: 991px) {

  .custom-results {
    padding: 50px 50px 0px;

    &__item {
      margin-bottom: 50px;
    }
  }

  .post-video {

    &__info {
      width: 40%;
      padding-right: 25px;
    }

    &__main {
      width: 60%;
      padding-right: 75px;

      font-size: 16px;
      p {
        margin: 20px 0;
      }
    }

    &__header {
      padding: 25px 15px;
      font-size: 16px;
    }

    &__date,
    &__tags,
    &__share {
      font-size: 16px;
    }
  }

  .timecode {
    &__item {
      padding: 15px 20px;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 767px) {

  .custom-results {

    margin-top: -80px;
    padding: 40px 30px 0;

    .section-title {
      margin-bottom: 30px;
    }

    &__item {
      font-size: 14px;
    }

    &__num {
      margin-bottom: 30px;
      font-size: 42px;

      &:before {
        bottom: -11px;
      }
    }

    &__item {
      margin-bottom: 35px;
      max-width: unset;
    }
  }

  .image-holder-custom {
    margin-top: 38px;
    padding-bottom: 0;
    img {
      position: relative;
      top: -78px;
      left: 50%;
      transform: translateX(-40%);
    }
  }

  .image-holder-custom-two {
    margin-top: 50px;
    max-height: 655px;
    img {
      position: relative;
      top: 50px;
    }
  }

  .post-video-header .pulse-button {
    top: 50%;
    right: 50%;
    transform: translate(-50%, -7 0%);
  }

  .post-video {
    &__info {
      width: 100%;
      padding-right: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &__main {
      width: 100%;
      padding: 0 15px;
    }

    &__header {
      width: 50%;
      margin-bottom: 0;
    }

    &__more {
      width: 50%;
      padding-left: 30px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .post-video {
    &__header,
    &__tags,
    &__date,
    &__main,
    &__share {
      font-size: 14px;
    }

    &-content .post-video__share {
      padding-left: 0px;
      margin-top: 0;
      display: flex;
      justify-content: flex-start;

      .post-video__link {
        width: 35px;
        height: 35px;
        margin-bottom: 5px;
      }
    }
  }

  .timecode {
    &__item {
      padding: 15px 15px;
      font-size: 14px;
    }
  }

  .custom-results {
    margin-top: 0;
    border: none;
    &__num {
      margin-bottom: 20px;
      font-size: 38px;
      &:before {
        bottom: -8px;
      }
    }
  }

}

@media only screen and (max-width: 480px) {
  .post-video {

    &-header {
      min-height: 200px;
    }

    &__header,
    &__more {
      width: 100%;
      margin: 10px 0;
    }

    &__more {
      display: flex;
      justify-content: space-between;
      padding-left: 0;
    }

    &__date,
    &__tags,
    &__share {
      flex: 1;
      display: block;
      .name {
        margin-bottom: 5px;
        span {
          display: none;
        }
      }
    }

    &__header {
      font-size: 13px;
      padding: 15px;
    }

    &__main p {
      margin: 15px 0;
    }
    &-content .post-video__share {
      flex-direction: column;
    }
  }
}
