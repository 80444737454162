@import "../structure/variables";



section.unique-practice {
  padding-top: 80px;
  padding-bottom: 1px;
  background-color: @white;
}
.unique-practice__intro {
  max-width: 600px;
  margin: 0 auto 90px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.2;
  color: @red_main;
  &::after {
    display: block;
    content: '';
    width: 52px;
    height: 1px;
    background-color: @red_main;
    margin: 25px auto 0;
  }
}
.unique-practice__list {
  max-width: 940px;
  margin: 0 auto;
}
.unique-practice__item {
  padding: 45px 0 35px;
  margin-bottom: 50px;
  &:nth-child(odd) {
    background-color: #f9f8fb;
  }
  &:hover .unique-practice__image {
    transform: scale(1.2);
  } 
}
.unique-practice__image {
  transition: all 0.5s;
}
.unique-practice__text {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.25;
  padding-right: 80px;
  p {
    margin-bottom: 20px;
  }
  a {
    color: #ff3043;
  }
}
.unique-practice__hidden-text {
  display: none;
}
.unique-practice__wrapper {
  position: relative;
}
.unique-practice__title {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  padding-left: 170px;
  padding-right: 10px;
}
.unique-practice__image {
  position: absolute;
  top: 0;
  left: 40px;
}
.unique-practice__button {
  display: none;
  font-size: 12px;
  font-weight: 700;
  color: #ff5464;
  letter-spacing: 2px;
  cursor: pointer;
  padding-right: 15px;
  &::after {
    display: inline-block;
    content: '';
    width: 7px;
    height: 7px;
    border-top: 2px solid #ff5464;
    border-right: 2px solid #ff5464;
    transform: rotate(135deg);
    margin-left: 12px;
    margin-bottom: 1px;
    transition: all 0.5s;
  }
}
.unique-practice__button--more {
  display: inline-block;
}
.unique-practice__button--less {
  &::after {
    transform: rotate(-45deg);
    margin-bottom: -3px;
  }
}

.unique-practice__text--open {
  .unique-practice__button--less {
    display: inline-block;
  }
  .unique-practice__button--more {
    display: none;
  }
}




@media only screen and (min-width: 992px) {
  .unique-practice__text {
    a:hover {
      text-decoration: underline;
    }
  }
  .unique-practice__button--more:hover {
    &::after {
      transform: translateX(5px) rotate(135deg);
    }
  }
  .unique-practice__button--less:hover {
    &::after {
      transform: translateX(5px) rotate(-45deg);
    }
  }
}


@media only screen and (max-width: 991px) {
  .unique-practice__text {
    padding-right: 20px;
  }
  .unique-practice__title {
    padding-left: 80px;
  }
  .unique-practice__image {
    left: -20px;
  }
}


@media only screen and (max-width: 767px) {
  section.unique-practice {
    padding-top: 40px;
    padding-bottom: 1px;
  }
  .unique-practice__wrapper {
    margin-bottom: 30px;
  }
  .unique-practice__intro {
    font-size: 16px;
    margin-bottom: 40px;
  }
  .unique-practice__item {
    margin-bottom: 30px;
    padding: 25px 15px;
  }
  .unique-practice__title {
    padding-right: 70px;
    font-size: 16px;
    padding-left: 0;
  }
  .unique-practice__text {
    padding-right: 0;
  }
  .unique-practice__image {
    max-width: 60px;
    max-height: 60px;
    left: auto;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}