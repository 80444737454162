@import "../structure/variables";

.page-wrap--marketing-approach {
  section.principles {
    padding: 120px 0 20px;
  }
  .principles-wrapper {
    margin-bottom: 0;
  }
  .principles-navigation__text--mobile {
    display: none;
  }
  .principles-list__item {
    a {
      display: none;
    }
  }
}

.hiddentext {
  padding: 65px 0;
  background: @white;

  .container {
    max-width: 986px;
  }

  &__title {
    position: relative;
    margin-bottom: 60px;

    span {
      display: block;
      font-size: 16px;
      text-transform: lowercase;
      margin-top: 15px;

    }

    &:before {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 54px;
      height: 2px;
      background: @red_main;
    }
  }

  &__container {
    max-width: 630px;
    margin: 0 auto;
  }
  &__text {
    position: relative;
    max-height: 190px;
    overflow: hidden;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;

    transition: 0.25s ease;

    p + p {
      margin-top: 20px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.21), #ffffff 100%);
      opacity: 1;
      visibility: visible;
      transition: 0.25s ease;
    }

    &.opened {
      max-height: 9999px;
      transition: 0.25s ease;
      &:after {
        opacity: 0;
        visibility: hidden;
        transition: 0.25s ease;
      }
    }
  }

  &__arrow {

    svg {
      cursor: pointer;
      display: block;
      margin: 0 auto;
      width: 25px;
      height: 15px;
    }

    &_hide {
      display: none;
    }
  }

}

.opened {
  .hiddentext__arrow_show,
  .hidden-list__arrow_show {
    display: none;
  }

  .hiddentext__arrow_hide,
  .hidden-list__arrow_hide {
    display: block;
  }
}

.services-list.services-list_results {
  padding-bottom: 5px;
  background: rgba(255, 0, 72, 0.1);

  .services-list__title {
    margin-bottom: 40px;
  }
}

.service-list_marketing {
  padding-bottom: 60px;
  .services-list__image {
    top: 0;
  }
}

.hidden-list {
  position: relative;
  max-height: 580px;
  overflow: hidden;

  transition: 0.5s linear;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.21) 40%, #ffffff 100%);
    opacity: 1;
    visibility: visible;
    transition: 0.25s ease;
  }

  &.opened {
    max-height: 9999px;
    transition: 0.5s linear;
    overflow: visible;
    &:after {
      opacity: 0;
      visibility: hidden;
      transition: 0.25s ease;
    }
  }

  &__arrow {
    svg {
      cursor: pointer;
      display: block;
      margin: 0 auto;
      width: 25px;
      height: 15px;
    }

    &_hide {
      display: none;
    }
  }

}

@media only screen and (max-width: 991px) {
  .page-wrap--marketing-approach {
    section.principles {
      padding: 60px 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .page-wrap--marketing-approach {
    .principles-navigation__item {
      margin-bottom: 50px;
      padding-left: 85px;
    }
    .principles-navigation__text {
      font-weight: 400 !important;
    }
    .principles-navigation__text--mobile {
      display: block;
      margin-bottom: 30px;
      font-weight: 400 !important;
      max-width: 100%;
    }
    .principles-navigation__image {
      max-width: 55px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media only screen and (max-width: 575px) {

  .page-wrap--marketing-page .marketing-list {
    padding: 40px 0;
  }

}