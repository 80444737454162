@import "../structure/variables";

section.contacts-main {
  padding-top: 60px;
  padding-bottom: 80px;
}
.contacts-main__title {
  font-size: 44px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.contacts-main__subtitle {
  max-width: 425px;
  font-size: 24px;
  color: @red_main;
  line-height: 1.33;
}
section.contacts-list {
  padding-bottom: 150px;
}
.contacts-list__title {
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 25px;
  span {
    vertical-align: middle;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }
}
.contacts-list__text {
  font-size: 17px;
  color: #544b5c;
  transition: all 0.5s;
  margin-bottom: 10px;
}
.contacts-list__link {
  display: inline-block;
  font-size: 15px;
  line-height: 1.6;
  color: @red_main;
  overflow: hidden;
  position: relative;
  transition: all 0.5s;
  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: @red_main;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #000000;
    transition: all 0.5s;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateX(-100%);
  }
}




@media only screen and (min-width: 992px) {
  .contacts-list__link:hover {
    color: #000000;
    &::after {
      transform: translateX(0);
    }
  }
  a.contacts-list__text:hover {
    color: @red_main;
  }
}


@media only screen and (max-width: 991px) {
  section.contacts-main {
    padding-top: 30px;
  }
  section.contacts-list {
    padding-bottom: 100px;
  }
}


@media only screen and (max-width: 767px) {
  section.contacts-main {
    padding-bottom: 50px;
  }
  .contacts-main__title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .contacts-main__subtitle {
    font-size: 18px;
  }
  .contacts-list__title {
    margin-bottom: 10px;
  }
  .contacts-list__item {
    margin-bottom: 30px;
  }
  section.contacts-list {
    padding-bottom: 50px;
  }
}