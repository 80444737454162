@import "../structure/variables";


section.vacancy-main {
  padding-top: 30px;
}
.vacancy-main__wrapper {
  max-width: 810px;
  padding: 0 15px;
  margin: 0 auto;
}
.vacancy-main__category {
  font-size: 17px;
  line-height: 1;
  color: @red_main;
  margin-bottom: 10px;
}
.vacancy-main__title {
  font-size: 29px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 30px;
}
.vacancy-main__description {
  margin-bottom: 30px;
  color: #544b5c;
  p {
    margin-bottom: 10px;
  }
}
.vacancy-main__subtitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: #544b5c;
  margin-bottom: 20px;
}
.vacancy-main__text {
  color: #544b5c;
  margin-bottom: 50px;
  p {
    margin-bottom: 10px;
  }
  ul {
    padding-left: 20px;
    margin-bottom: 25px;
  }
}
.vacancy-main__image {
  margin: 0 auto -60px;
}
section.vacancy-apply--vacancy-page {
  padding-top: 100px;
}





@media only screen and (max-width: 767px) {
  .vacancy-main__title {
    font-size: 22px;
    line-height: 1.25;
  }
}