@white: #fff;
@red_main: #ff0048;
@black: #000;
@article-card-category-color: #ff5464;



@keyframes pulse {to {box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);}}
@keyframes pulse_company {to {box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.15);}}

@keyframes upButtPulse {
0 {transform: translate(0, 0);}
50% {transform: translate(0, 40px);}
100% {transform: translate(0, 0);}
}

@keyframes downButtPulse {
0,
85%,
100% {
  transform: translate(0, 0);
}
80% {
  transform: translate(-10px, 0);
}
90% {
  transform: translate(10px, 0);
}
}

@transition_company_relation: opacity 0.6s 3.1s ease-in-out;
