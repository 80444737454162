@import "../structure/variables";


section.approach-list-general {
  background-color: @white;
  padding: 65px 0 0;
}
.approach-list-general-row {
  margin-bottom: -50px;
  align-items: flex-end;
}
.approach-list-general-wrapper {
  padding-bottom: 120px;
}
.approach-list-general__text {
  line-height: 1.6;
  margin-bottom: 50px;
}
.approach-list-general__image {
  margin-left: auto;
}
.approach-list-general__link {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;
  transition: all 0.5s;
  span {
    margin-right: 10px;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    transition: all 0.5s;
    margin-top: -2px;
  }
}


section.approach-list {
  padding-top: 150px;
  padding-bottom: 100px;
  background-color: #f7f5f7;
}
.approach-list__title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 50px;
  text-align: center;
}
.approach-list__item {
  padding: 50px 40px 40px 140px;
  position: relative;
  transition: all 0.5s;
}
.approach-list__text {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.approach-list__image {
  position: absolute;
  top: 50px;
  left: 30px;
  width: 65px;
}
.approach-list__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}



section.practice-list {
  background-color: @white;
  padding-top: 80px;
  padding-bottom: 90px;
  position: relative;
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: @white;
    left: 0;
    width: 50%;
    z-index: 2;
  }
}
.section-title--practice-list {
  font-size: 23px;
  text-align: left;
  &::after {
    display: block;
    width: 50px;
    content: '';
    height: 1px;
    background-color: #ff5464;
    margin: 25px 0 30px;
  }
}
.practice-list-row {
  min-height: 300px;
}
.practice-list__text {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.55;
  //max-width: 360px;
  margin-bottom: 35px;
}
.practice-list-wrapper {
  z-index: 3;
}
.practice-list-slider {
  position: absolute;
  left: 50%;
  top: 60px;
  width: 1360px;
  .owl-stage-outer {
    overflow: visible;
  }
}
.practice-list__navigation {
  display: flex;
  align-items: center;
}
.practice-list__arrow {
  cursor: pointer;
}
.practice-list__arrow-path {
  transition: all 0.5s;
}
.practice-list__arrow--left {
  margin-right: 25px;
  transform: rotate(180deg);
}
.practice-list__item {
  width: 300px;
  height: 300px;
  margin: 20px;
  padding: 40px 20px;
  position: relative;
  transition: all 0.5s;
}
.practice-list__item-wrapper {
  height: 220px;
  overflow: hidden;
  position: relative;
}
.practice-list__image {
  width: auto !important;
  height: 80px;
  margin-bottom: 25px;
}
.practice-list__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  margin-bottom: 10px;
}
.practice-list__text--item {
  transition: all 0.3s;
}
.practice-list__show-more {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.5s;
  span {
    margin-right: 10px;
  }
  svg {
    margin-top: -2px;
  }
}
.practice-list__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}




@media only screen and (min-width: 992px) {
  .approach-list-general__link:hover {
    color: @red_main;
    svg {
      transform: translateX(10px);
    }
  }
  .approach-list__item:hover {
    box-shadow: 0 0 13px 0 rgba(182, 173, 189, 0.47);
  }
  .practice-list__item:hover {
    box-shadow: 0 0 19px 0 rgba(130, 130, 130, 0.21);
    .practice-list__show-more {
      opacity: 1;
    }
    .practice-list__text--item {
      opacity: 0;
    }
  }
  .practice-list__arrow:hover {
    .practice-list__arrow-path {
      stroke: @red_main;
    }
  }
}


@media only screen and (max-width: 991px) {
  section.approach-list-general {
    padding: 60px 0;
  }
  .approach-list-general-wrapper {
    padding-bottom: 0;
  }
  .approach-list-general-row {
    margin-bottom: 0;
    align-items: center;
  }
  .approach-list-general__text {
    line-height: 1.5;
    margin-bottom: 30px;
  }
  section.approach-list {
    padding: 60px 0;
  }
  .approach-list__item {
    padding: 30px 10px 30px 70px;
  }
  .approach-list__image {
    top: 30px;
    left: 0;
    width: 50px;
  }
  .approach-list__title {
    margin-bottom: 30px;
  }


  section.practice-list {
    padding-top: 60px;
    padding-bottom: 30px;
    &::after {
      display: none;
    }
  }
  .practice-list-slider {
    position: relative;
    top: 0;
    left: 0;
  }
  .practice-list-row {
    min-height: 0;
  }
  .practice-list__text {
    max-width: 600px;
  }
}


@media only screen and (max-width: 767px) {
  section.approach-list-general {
    padding: 40px 0 50px;
  }
  .approach-list-general__image {
    margin-bottom: 20px;
  }
  section.approach-list {
    padding: 50px 0;
  }
  .approach-list__title {
    font-size: 20px;
  }
}