@import "../structure/variables";

.section-title--new-works {
  margin-bottom: 40px;
}

section.works-list {
  padding: 100px 15px 20px;
  background-color: @white;
  &--home {
    padding-top: 100px;
  }
}

.works-list__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25;
  text-transform: uppercase;
  margin-bottom: 45px;
  text-align: center;
}

.works-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 960px;
  margin: 0 auto;
}

.work-card-wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 20px;
  transition: opacity 1s ease, transform 1s;
  //image height fix
  margin-bottom: 40px;
  max-height: 336px;

  &.animate {
    opacity: 0;
    transform: translateY(100px);
  }
}

.work-card {
  //margin-bottom: 40px;
  height: 100%;
  position: relative;
  overflow: hidden;
  .work-card__image {
    transition: transform 1s;

    height: 100%;
    object-fit: cover;

  }
  &:hover .work-card__image {
    transform: scale(1.1);
  }
}

.work-card__info {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 0, 72, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.work-card__title {
  font-size: 20px;
  color: @white;
  font-weight: 800;
  line-height: 1.3;
  margin-bottom: 10px;
}

.work-card__text {
  line-height: 1.25;
  max-width: 270px;
  color: @white;
}

.work-card__arrow {
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
}

.work-card__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.works-list__button-wrapper {
  text-align: center;
}

/*.works-list__button {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  color: #544b5c;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 50px 15px;
  min-width: 230px;
  border-radius: 100px;
  background-color: rgba(84, 75, 92, 0.15);
  transition: all 0.5s;
}*/

@media only screen and (min-width: 992px) {
  .work-card__info:hover {
    opacity: 1;
  }

  .works-list__button:hover {
    color: @red_main;
  }
}

@media only screen and (max-width: 991px) {
  .work-card__info {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  section.works-list {
    padding: 40px 15px;
  }

  .work-card-wrapper {
    flex: auto;
    max-width: 100%;
    padding: 0;
  }

  .work-card-wrapper {
    margin-bottom: 30px;

    .work-card__image {
      height: auto;
    }
  }
}